import React, { useEffect, useState } from "react";
import { Box, Heading, Stack, Text } from "@chakra-ui/layout";
import { useParams } from "react-router";
import { Skeleton } from "@chakra-ui/skeleton";
import EditModelTitleFragment from "./EditModelTitleFragment";
import axiosInstance from "../../helpers/axios";
import ModelUses from "./ModelUses";
import ModelSingleUse from "./ModelSingleUse";
import AddModelUse from "./AddModelUse";

function EditModelFragment(props) {
  const { variable } = useParams();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("vehicletype/" + variable)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          console.log(res.message);
        } else {
          setModel(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Stack>
      <Heading>
        <Text>Vehicle Type</Text>

        <Box mt={2} h={"2px"} w={"60%"} maxW="100px" bg={"accent.1"} />
      </Heading>

      {model !== undefined && !loading && (
        <EditModelTitleFragment model={model} />
      )}
      {model !== undefined && !loading && <ModelUses model={model} />}
      {model &&
        model.uses.map((use, index) => (
          <ModelSingleUse key={index} model={model} use={use} />
        ))}
      {model && <AddModelUse model={model} setModel={setModel} />}
    </Stack>
  );
}

export default EditModelFragment;
