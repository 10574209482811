export const makes = [
  {
    name: "Abarth",
    models: [
      { name: "124 Spider", series: null },
      { name: "500", series: null },
      { name: "500C", series: null },
      { name: "595", series: null },
      { name: "595C", series: null },
      { name: "595 Competizione", series: null },
      { name: "595 Turismo", series: null },
      { name: "695", series: null },
      { name: "695C", series: null },
      { name: "Grande Punto", series: null },
      { name: "Punto Evo", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "AC", models: [{ name: "Other", series: null }] },
  {
    name: "Acura",
    models: [
      { name: "MDX", series: null },
      { name: "NSX", series: null },
      { name: "RL", series: null },
      { name: "RSX", series: null },
      { name: "TL", series: null },
      { name: "TSX", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Aixam",
    models: [
      { name: "City", series: null },
      { name: "Crossline", series: null },
      { name: "Roadline", series: null },
      { name: "Scouty R", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Alfa Romeo",
    models: [
      { name: "4C", series: null },
      { name: "8C", series: null },
      { name: "Alfa 145", series: null },
      { name: "Alfa 146", series: null },
      { name: "Alfa 147", series: null },
      { name: "Alfa 155", series: null },
      { name: "Alfa 156", series: null },
      { name: "Alfa 159", series: null },
      { name: "Alfa 164", series: null },
      { name: "Alfa 166", series: null },
      { name: "Alfa 33", series: null },
      { name: "Alfa 75", series: null },
      { name: "Alfa 90", series: null },
      { name: "Alfasud", series: null },
      { name: "Alfetta", series: null },
      { name: "Brera", series: null },
      { name: "Crosswagon", series: null },
      { name: "Giulia", series: null },
      { name: "Giulietta", series: null },
      { name: "GT", series: null },
      { name: "GTV", series: null },
      { name: "Junior", series: null },
      { name: "MiTo", series: null },
      { name: "Spider", series: null },
      { name: "Sprint", series: null },
      { name: "Stelvio", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "ALPINA",
    models: [
      { name: "B10", series: null },
      { name: "B12", series: null },
      { name: "B3", series: null },
      { name: "B4", series: null },
      { name: "B5", series: null },
      { name: "B6", series: null },
      { name: "B7", series: null },
      { name: "B8", series: null },
      { name: "D10", series: null },
      { name: "D3", series: null },
      { name: "D4", series: null },
      { name: "D5", series: null },
      { name: "Roadster S", series: null },
      { name: "XD3", series: null },
      { name: "XD4", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Artega",
    models: [
      { name: "GT", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Asia Motors",
    models: [
      { name: "Rocsta", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Aston Martin",
    models: [
      { name: "AR1", series: null },
      { name: "Cygnet", series: null },
      { name: "DB", series: null },
      { name: "DB11", series: null },
      { name: "DB7", series: null },
      { name: "DB9", series: null },
      { name: "DBS", series: null },
      { name: "Lagonda", series: null },
      { name: "Rapide", series: null },
      { name: "V12 Vantage", series: null },
      { name: "V8 Vantage", series: null },
      { name: "Vanquish", series: null },
      { name: "Virage", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Audi",
    models: [
      { name: "100", series: null },
      { name: "200", series: null },
      { name: "80", series: null },
      { name: "90", series: null },
      { name: "A1", series: null },
      { name: "A2", series: null },
      { name: "A3", series: null },
      { name: "A4", series: null },
      { name: "A4 Allroad", series: null },
      { name: "A5", series: null },
      { name: "A6", series: null },
      { name: "A6 Allroad", series: null },
      { name: "A7", series: null },
      { name: "A8", series: null },
      { name: "Cabriolet", series: null },
      { name: "Coupé", series: null },
      { name: "e-tron", series: null },
      { name: "Q1", series: null },
      { name: "Q2", series: null },
      { name: "Q3", series: null },
      { name: "Q5", series: null },
      { name: "Q7", series: null },
      { name: "Q8", series: null },
      { name: "quattro", series: null },
      { name: "R8", series: null },
      { name: "RS2", series: null },
      { name: "RS3", series: null },
      { name: "RS4", series: null },
      { name: "RS5", series: null },
      { name: "RS6", series: null },
      { name: "RS7", series: null },
      { name: "RSQ3", series: null },
      { name: "S1", series: null },
      { name: "S2", series: null },
      { name: "S3", series: null },
      { name: "S4", series: null },
      { name: "S5", series: null },
      { name: "S6", series: null },
      { name: "S7", series: null },
      { name: "S8", series: null },
      { name: "SQ2", series: null },
      { name: "SQ5", series: null },
      { name: "SQ7", series: null },
      { name: "SQ8", series: null },
      { name: "TT", series: "TT" },
      { name: "TT RS", series: "TT" },
      { name: "TTS", series: "TT" },
      { name: "V8", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Austin", models: [{ name: "Other", series: null }] },
  { name: "Austin Healey", models: [{ name: "Other", series: null }] },
  {
    name: "Bentley",
    models: [
      { name: "Arnage", series: null },
      { name: "Azure", series: null },
      { name: "Bentayga", series: null },
      { name: "Brooklands", series: null },
      { name: "Continental", series: "Continental" },
      { name: "Continental Flying Spur", series: "Continental" },
      { name: "Continental GT", series: "Continental" },
      { name: "Continental GTC", series: "Continental" },
      { name: "Continental Supersports", series: "Continental" },
      { name: "Eight", series: null },
      { name: "Flying Spur", series: null },
      { name: "Mulsanne", series: null },
      { name: "Turbo R", series: null },
      { name: "Turbo RT", series: null },
      { name: "Turbo S", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "BMW",
    models: [
      { name: "114", series: "1 Series" },
      { name: "116", series: "1 Series" },
      { name: "118", series: "1 Series" },
      { name: "120", series: "1 Series" },
      { name: "123", series: "1 Series" },
      { name: "125", series: "1 Series" },
      { name: "130", series: "1 Series" },
      { name: "135", series: "1 Series" },
      { name: "1er M Coupé", series: "1 Series" },
      { name: "2002", series: null },
      { name: "214 Active Tourer", series: "2 Series" },
      { name: "214 Gran Tourer", series: "2 Series" },
      { name: "216", series: "2 Series" },
      { name: "216 Active Tourer", series: "2 Series" },
      { name: "216 Gran Tourer", series: "2 Series" },
      { name: "218", series: "2 Series" },
      { name: "218 Active Tourer", series: "2 Series" },
      { name: "218 Gran Tourer", series: "2 Series" },
      { name: "220", series: "2 Series" },
      { name: "220 Active Tourer", series: "2 Series" },
      { name: "220 Gran Tourer", series: "2 Series" },
      { name: "225", series: "2 Series" },
      { name: "225 Active Tourer", series: "2 Series" },
      { name: "228", series: "2 Series" },
      { name: "230", series: "2 Series" },
      { name: "315", series: "3 Series" },
      { name: "316", series: "3 Series" },
      { name: "318", series: "3 Series" },
      { name: "318 Gran Turismo", series: "3 Series" },
      { name: "320", series: "3 Series" },
      { name: "320 Gran Turismo", series: "3 Series" },
      { name: "323", series: "3 Series" },
      { name: "324", series: "3 Series" },
      { name: "325", series: "3 Series" },
      { name: "325 Gran Turismo", series: "3 Series" },
      { name: "328", series: "3 Series" },
      { name: "328 Gran Turismo", series: "3 Series" },
      { name: "330", series: "3 Series" },
      { name: "330 Gran Turismo", series: "3 Series" },
      { name: "335", series: "3 Series" },
      { name: "335 Gran Turismo", series: "3 Series" },
      { name: "340", series: "3 Series" },
      { name: "340 Gran Turismo", series: "3 Series" },
      { name: "ActiveHybrid 3", series: "3 Series" },
      { name: "418", series: "4 Series" },
      { name: "418 Gran Coupé", series: "4 Series" },
      { name: "420", series: "4 Series" },
      { name: "420 Gran Coupé", series: "4 Series" },
      { name: "425", series: "4 Series" },
      { name: "425 Gran Coupé", series: "4 Series" },
      { name: "428", series: "4 Series" },
      { name: "428 Gran Coupé", series: "4 Series" },
      { name: "430", series: "4 Series" },
      { name: "430 Gran Coupé", series: "4 Series" },
      { name: "435", series: "4 Series" },
      { name: "435 Gran Coupé", series: "4 Series" },
      { name: "440", series: "4 Series" },
      { name: "440 Gran Coupé", series: "4 Series" },
      { name: "518", series: "5 Series" },
      { name: "520", series: "5 Series" },
      { name: "520 Gran Turismo", series: "5 Series" },
      { name: "523", series: "5 Series" },
      { name: "524", series: "5 Series" },
      { name: "525", series: "5 Series" },
      { name: "528", series: "5 Series" },
      { name: "530", series: "5 Series" },
      { name: "530 Gran Turismo", series: "5 Series" },
      { name: "535", series: "5 Series" },
      { name: "535 Gran Turismo", series: "5 Series" },
      { name: "540", series: "5 Series" },
      { name: "545", series: "5 Series" },
      { name: "550", series: "5 Series" },
      { name: "550 Gran Turismo", series: "5 Series" },
      { name: "ActiveHybrid 5", series: "5 Series" },
      { name: "620 Gran Turismo", series: "6 Series" },
      { name: "628", series: "6 Series" },
      { name: "630", series: "6 Series" },
      { name: "630 Gran Turismo", series: "6 Series" },
      { name: "633", series: "6 Series" },
      { name: "635", series: "6 Series" },
      { name: "640", series: "6 Series" },
      { name: "640 Gran Coupé", series: "6 Series" },
      { name: "640 Gran Turismo", series: "6 Series" },
      { name: "645", series: "6 Series" },
      { name: "650", series: "6 Series" },
      { name: "650 Gran Coupé", series: "6 Series" },
      { name: "725", series: "7 Series" },
      { name: "728", series: "7 Series" },
      { name: "730", series: "7 Series" },
      { name: "732", series: "7 Series" },
      { name: "735", series: "7 Series" },
      { name: "740", series: "7 Series" },
      { name: "745", series: "7 Series" },
      { name: "750", series: "7 Series" },
      { name: "760", series: "7 Series" },
      { name: "ActiveHybrid 7", series: "7 Series" },
      { name: "840", series: null },
      { name: "850", series: null },
      { name: "i3", series: null },
      { name: "i8", series: null },
      { name: "M135", series: "M Models" },
      { name: "M140i", series: "M Models" },
      { name: "M2", series: "M Models" },
      { name: "M235", series: "M Models" },
      { name: "M240i", series: "M Models" },
      { name: "M3", series: "M Models" },
      { name: "M340i", series: "M Models" },
      { name: "M4", series: "M Models" },
      { name: "M5", series: "M Models" },
      { name: "M550", series: "M Models" },
      { name: "M6", series: "M Models" },
      { name: "M760", series: "M Models" },
      { name: "M850", series: "M Models" },
      { name: "ActiveHybrid X6", series: "X Series" },
      { name: "X1", series: "X Series" },
      { name: "X2", series: "X Series" },
      { name: "X3", series: "X Series" },
      { name: "X3 M", series: "X Series" },
      { name: "X3 M40", series: "X Series" },
      { name: "X4", series: "X Series" },
      { name: "X4 M", series: "X Series" },
      { name: "X4 M40", series: "X Series" },
      { name: "X5", series: "X Series" },
      { name: "X5 M", series: "X Series" },
      { name: "X5 M50", series: "X Series" },
      { name: "X6", series: "X Series" },
      { name: "X6 M", series: "X Series" },
      { name: "X6 M50", series: "X Series" },
      { name: "X7", series: "X Series" },
      { name: "Z1", series: "Z Series" },
      { name: "Z3", series: "Z Series" },
      { name: "Z3 M", series: "Z Series" },
      { name: "Z4", series: "Z Series" },
      { name: "Z4 M", series: "Z Series" },
      { name: "Z8", series: "Z Series" },
      { name: "Other", series: null },
    ],
  },
  { name: "Borgward", models: [{ name: "Other", series: null }] },
  {
    name: "Brilliance",
    models: [
      { name: "BC3", series: null },
      { name: "BS2", series: null },
      { name: "BS4", series: null },
      { name: "BS6", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Bugatti",
    models: [
      { name: "Chiron", series: null },
      { name: "EB 110", series: null },
      { name: "Veyron", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Buick",
    models: [
      { name: "Century", series: null },
      { name: "Electra", series: null },
      { name: "Enclave", series: null },
      { name: "La Crosse", series: null },
      { name: "Le Sabre", series: null },
      { name: "Park Avenue", series: null },
      { name: "Regal", series: null },
      { name: "Riviera", series: null },
      { name: "Roadmaster", series: null },
      { name: "Skylark", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Cadillac",
    models: [
      { name: "Allante", series: null },
      { name: "ATS", series: null },
      { name: "BLS", series: null },
      { name: "CT6", series: null },
      { name: "CTS", series: null },
      { name: "Deville", series: null },
      { name: "Eldorado", series: null },
      { name: "Escalade", series: null },
      { name: "Fleetwood", series: null },
      { name: "Seville", series: null },
      { name: "SRX", series: null },
      { name: "STS", series: null },
      { name: "XLR", series: null },
      { name: "XT5", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Casalini", models: [{ name: "Other", series: null }] },
  { name: "Caterham", models: [{ name: "Other", series: null }] },
  { name: "Chatenet", models: [{ name: "Other", series: null }] },
  {
    name: "Chevrolet",
    models: [
      { name: "2500", series: null },
      { name: "Alero", series: null },
      { name: "Astro", series: null },
      { name: "Avalanche", series: null },
      { name: "Aveo", series: null },
      { name: "Beretta", series: null },
      { name: "Blazer", series: null },
      { name: "C1500", series: null },
      { name: "Camaro", series: null },
      { name: "Caprice", series: null },
      { name: "Captiva", series: null },
      { name: "Cavalier", series: null },
      { name: "Chevelle", series: null },
      { name: "Chevy Van", series: null },
      { name: "Citation", series: null },
      { name: "Colorado", series: null },
      { name: "Corsica", series: null },
      { name: "Cruze", series: null },
      { name: "El Camino", series: null },
      { name: "Epica", series: null },
      { name: "Evanda", series: null },
      { name: "Express", series: null },
      { name: "G", series: null },
      { name: "HHR", series: null },
      { name: "Impala", series: null },
      { name: "K1500", series: null },
      { name: "K30", series: null },
      { name: "Kalos", series: null },
      { name: "Lacetti", series: null },
      { name: "Lumina", series: null },
      { name: "Malibu", series: null },
      { name: "Matiz", series: null },
      { name: "Niva", series: null },
      { name: "Nubira", series: null },
      { name: "Orlando", series: null },
      { name: "Rezzo", series: null },
      { name: "S-10", series: null },
      { name: "Silverado", series: null },
      { name: "Spark", series: null },
      { name: "SSR", series: null },
      { name: "Suburban", series: null },
      { name: "Tahoe", series: null },
      { name: "Trailblazer", series: null },
      { name: "Trans Sport", series: null },
      { name: "Traverse", series: null },
      { name: "Trax", series: null },
      { name: "Venture", series: null },
      { name: "Volt", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Chrysler",
    models: [
      { name: "200", series: null },
      { name: "300C", series: null },
      { name: "300 M", series: null },
      { name: "Aspen", series: null },
      { name: "Crossfire", series: null },
      { name: "Daytona", series: null },
      { name: "ES", series: null },
      { name: "Grand Voyager", series: null },
      { name: "GS", series: null },
      { name: "GTS", series: null },
      { name: "Imperial", series: null },
      { name: "Le Baron", series: null },
      { name: "Neon", series: null },
      { name: "New Yorker", series: null },
      { name: "Pacifica", series: null },
      { name: "PT Cruiser", series: null },
      { name: "Saratoga", series: null },
      { name: "Sebring", series: null },
      { name: "Stratus", series: null },
      { name: "Valiant", series: null },
      { name: "Viper", series: null },
      { name: "Vision", series: null },
      { name: "Voyager", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Citroën",
    models: [
      { name: "2 CV", series: null },
      { name: "AX", series: null },
      { name: "Berlingo", series: null },
      { name: "BX", series: null },
      { name: "C1", series: null },
      { name: "C2", series: null },
      { name: "C3", series: null },
      { name: "C3 Aircross", series: null },
      { name: "C3 Picasso", series: null },
      { name: "C4", series: null },
      { name: "C4 Aircross", series: null },
      { name: "C4 Cactus", series: null },
      { name: "C4 Picasso", series: null },
      { name: "C4 SpaceTourer", series: null },
      { name: "C5", series: null },
      { name: "C5 Aircross", series: null },
      { name: "C6", series: null },
      { name: "C8", series: null },
      { name: "C-Crosser", series: null },
      { name: "C-Elysée", series: null },
      { name: "CX", series: null },
      { name: "C-Zero", series: null },
      { name: "DS", series: null },
      { name: "DS3", series: null },
      { name: "DS4", series: null },
      { name: "DS4 Crossback", series: null },
      { name: "DS5", series: null },
      { name: "E-MEHARI", series: null },
      { name: "Evasion", series: null },
      { name: "Grand C4 Picasso / SpaceTourer", series: null },
      { name: "GSA", series: null },
      { name: "Jumper", series: null },
      { name: "Jumpy", series: null },
      { name: "Nemo", series: null },
      { name: "SAXO", series: null },
      { name: "SM", series: null },
      { name: "SpaceTourer", series: null },
      { name: "Visa", series: null },
      { name: "Xantia", series: null },
      { name: "XM", series: null },
      { name: "Xsara", series: null },
      { name: "Xsara Picasso", series: null },
      { name: "ZX", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Cobra", models: [{ name: "Other", series: null }] },
  {
    name: "Corvette",
    models: [
      { name: "C1", series: null },
      { name: "C2", series: null },
      { name: "C3", series: null },
      { name: "C4", series: null },
      { name: "C5", series: null },
      { name: "C6", series: null },
      { name: "C7", series: null },
      { name: "C8", series: null },
      { name: "Z06", series: null },
      { name: "ZR 1", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Cupra",
    models: [
      { name: "Arona", series: null },
      { name: "Ateca", series: null },
      { name: "Ibiza", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Dacia",
    models: [
      { name: "Dokker", series: null },
      { name: "Duster", series: null },
      { name: "Lodgy", series: null },
      { name: "Logan", series: null },
      { name: "Logan Pick-Up", series: null },
      { name: "Pick Up", series: null },
      { name: "Sandero", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Daewoo",
    models: [
      { name: "Espero", series: null },
      { name: "Evanda", series: null },
      { name: "Kalos", series: null },
      { name: "Korando", series: null },
      { name: "Lacetti", series: null },
      { name: "Lanos", series: null },
      { name: "Leganza", series: null },
      { name: "Matiz", series: null },
      { name: "Musso", series: null },
      { name: "Nexia", series: null },
      { name: "Nubira", series: null },
      { name: "Rezzo", series: null },
      { name: "Tacuma", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Daihatsu",
    models: [
      { name: "Applause", series: null },
      { name: "Charade", series: null },
      { name: "Charmant", series: null },
      { name: "Copen", series: null },
      { name: "Cuore", series: null },
      { name: "Feroza/Sportrak", series: null },
      { name: "Freeclimber", series: null },
      { name: "Gran Move", series: null },
      { name: "Hijet", series: null },
      { name: "MATERIA", series: null },
      { name: "Move", series: null },
      { name: "Rocky/Fourtrak", series: null },
      { name: "Sirion", series: null },
      { name: "Terios", series: null },
      { name: "TREVIS", series: null },
      { name: "YRV", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "DeTomaso",
    models: [
      { name: "Guarà", series: null },
      { name: "Pantera", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Dodge",
    models: [
      { name: "Avenger", series: null },
      { name: "Caliber", series: null },
      { name: "Challenger", series: null },
      { name: "Charger", series: null },
      { name: "Dakota", series: null },
      { name: "Dart", series: null },
      { name: "Demon", series: null },
      { name: "Durango", series: null },
      { name: "Grand Caravan", series: null },
      { name: "Hornet", series: null },
      { name: "Journey", series: null },
      { name: "Magnum", series: null },
      { name: "Neon", series: null },
      { name: "Nitro", series: null },
      { name: "RAM", series: null },
      { name: "Stealth", series: null },
      { name: "Viper", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Donkervoort",
    models: [
      { name: "D8", series: null },
      { name: "S7", series: null },
      { name: "S8", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "DS Automobiles",
    models: [
      { name: "DS3", series: null },
      { name: "DS3 Crossback", series: null },
      { name: "DS4", series: null },
      { name: "DS4 Crossback", series: null },
      { name: "DS5", series: null },
      { name: "DS7 Crossback", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Ferrari",
    models: [
      { name: "208", series: null },
      { name: "246", series: null },
      { name: "250", series: null },
      { name: "275", series: null },
      { name: "288", series: null },
      { name: "308", series: null },
      { name: "328", series: null },
      { name: "330", series: null },
      { name: "348", series: null },
      { name: "360", series: null },
      { name: "365", series: null },
      { name: "400", series: null },
      { name: "412", series: null },
      { name: "456", series: null },
      { name: "458", series: null },
      { name: "488 GTB", series: null },
      { name: "488 Pista", series: null },
      { name: "488 Spider", series: null },
      { name: "512", series: null },
      { name: "550", series: null },
      { name: "575", series: null },
      { name: "599 GTB", series: null },
      { name: "599 GTO", series: null },
      { name: "599 SA Aperta", series: null },
      { name: "612", series: null },
      { name: "750", series: null },
      { name: "812", series: null },
      { name: "California", series: null },
      { name: "Daytona", series: null },
      { name: "Dino GT4", series: null },
      { name: "Enzo Ferrari", series: null },
      { name: "F12", series: null },
      { name: "F355", series: null },
      { name: "F40", series: null },
      { name: "F430", series: null },
      { name: "F50", series: null },
      { name: "FF", series: null },
      { name: "GTC4Lusso", series: null },
      { name: "LaFerrari", series: null },
      { name: "Mondial", series: null },
      { name: "Portofino", series: null },
      { name: "Superamerica", series: null },
      { name: "Testarossa", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Fiat",
    models: [
      { name: "124", series: null },
      { name: "124 Spider", series: null },
      { name: "126", series: null },
      { name: "127", series: null },
      { name: "130", series: null },
      { name: "131", series: null },
      { name: "500", series: null },
      { name: "500C", series: null },
      { name: "500L", series: null },
      { name: "500L Cross", series: null },
      { name: "500L Living", series: null },
      { name: "500L Trekking", series: null },
      { name: "500L Urban", series: null },
      { name: "500L Wagon", series: null },
      { name: "500S", series: null },
      { name: "500X", series: null },
      { name: "Albea", series: null },
      { name: "Barchetta", series: null },
      { name: "Brava", series: null },
      { name: "Bravo", series: null },
      { name: "Cinquecento", series: null },
      { name: "Coupe", series: null },
      { name: "Croma", series: null },
      { name: "Dino", series: null },
      { name: "Doblo", series: null },
      { name: "Ducato", series: null },
      { name: "Fiorino", series: null },
      { name: "Freemont", series: null },
      { name: "Fullback", series: null },
      { name: "Grande Punto", series: null },
      { name: "Idea", series: null },
      { name: "Linea", series: null },
      { name: "Marea", series: null },
      { name: "Marengo", series: null },
      { name: "Multipla", series: null },
      { name: "New Panda", series: null },
      { name: "Palio", series: null },
      { name: "Panda", series: null },
      { name: "Punto", series: null },
      { name: "Punto Evo", series: null },
      { name: "Qubo", series: null },
      { name: "Regata", series: null },
      { name: "Ritmo", series: null },
      { name: "Scudo", series: null },
      { name: "Sedici", series: null },
      { name: "Seicento", series: null },
      { name: "Siena", series: null },
      { name: "Spider Europa", series: null },
      { name: "Stilo", series: null },
      { name: "Strada", series: null },
      { name: "Talento", series: null },
      { name: "Tempra", series: null },
      { name: "Tipo", series: null },
      { name: "Ulysse", series: null },
      { name: "Uno", series: null },
      { name: "X 1/9", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Fisker",
    models: [
      { name: "Karma", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Ford",
    models: [
      { name: "Aerostar", series: null },
      { name: "B-Max", series: null },
      { name: "Bronco", series: null },
      { name: "Capri", series: null },
      { name: "C-Max", series: null },
      { name: "Cougar", series: null },
      { name: "Courier", series: null },
      { name: "Crown", series: null },
      { name: "Econoline", series: null },
      { name: "Econovan", series: null },
      { name: "EcoSport", series: null },
      { name: "Edge", series: null },
      { name: "Escape", series: null },
      { name: "Escort", series: null },
      { name: "Excursion", series: null },
      { name: "Expedition", series: null },
      { name: "Explorer", series: null },
      { name: "Express", series: null },
      { name: "F 100", series: null },
      { name: "F 150", series: null },
      { name: "F 250", series: null },
      { name: "F 350", series: null },
      { name: "Fairlane", series: null },
      { name: "Falcon", series: null },
      { name: "Fiesta", series: null },
      { name: "Flex", series: null },
      { name: "Focus", series: null },
      { name: "Fusion", series: null },
      { name: "Galaxy", series: null },
      { name: "Granada", series: null },
      { name: "Grand C-Max", series: null },
      { name: "Grand Tourneo", series: null },
      { name: "GT", series: null },
      { name: "Ka/Ka+", series: null },
      { name: "Kuga", series: null },
      { name: "Maverick", series: null },
      { name: "Mercury", series: null },
      { name: "Mondeo", series: null },
      { name: "Mustang", series: null },
      { name: "Orion", series: null },
      { name: "Probe", series: null },
      { name: "Puma", series: null },
      { name: "Ranger", series: null },
      { name: "Raptor", series: null },
      { name: "Scorpio", series: null },
      { name: "Sierra", series: null },
      { name: "S-Max", series: null },
      { name: "Sportka", series: null },
      { name: "Streetka", series: null },
      { name: "Taunus", series: null },
      { name: "Taurus", series: null },
      { name: "Thunderbird", series: null },
      { name: "Tourneo", series: "Tourneo" },
      { name: "Tourneo Connect", series: "Tourneo" },
      { name: "Tourneo Courier", series: "Tourneo" },
      { name: "Tourneo Custom", series: "Tourneo" },
      { name: "Transit", series: "Transit" },
      { name: "Transit Connect", series: "Transit" },
      { name: "Transit Courier", series: "Transit" },
      { name: "Transit Custom", series: "Transit" },
      { name: "Windstar", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "GAC Gonow", models: [{ name: "Other", series: null }] },
  { name: "Gemballa", models: [{ name: "Other", series: null }] },
  {
    name: "GMC",
    models: [
      { name: "Acadia", series: null },
      { name: "Envoy", series: null },
      { name: "Safari", series: null },
      { name: "Savana", series: null },
      { name: "Sierra", series: null },
      { name: "Sonoma", series: null },
      { name: "Syclone", series: null },
      { name: "Terrain", series: null },
      { name: "Typhoon", series: null },
      { name: "Vandura", series: null },
      { name: "Yukon", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Grecav",
    models: [
      { name: "Sonique", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Hamann", models: [{ name: "Other", series: null }] },
  { name: "Holden", models: [{ name: "Other", series: null }] },
  {
    name: "Honda",
    models: [
      { name: "Accord", series: null },
      { name: "Aerodeck", series: null },
      { name: "City", series: null },
      { name: "Civic", series: null },
      { name: "Clarity", series: null },
      { name: "Concerto", series: null },
      { name: "CR-V", series: null },
      { name: "CRX", series: null },
      { name: "CR-Z", series: null },
      { name: "e", series: null },
      { name: "Element", series: null },
      { name: "FR-V", series: null },
      { name: "HR-V", series: null },
      { name: "Insight", series: null },
      { name: "Integra", series: null },
      { name: "Jazz", series: null },
      { name: "Legend", series: null },
      { name: "Logo", series: null },
      { name: "NSX", series: null },
      { name: "Odyssey", series: null },
      { name: "Pilot", series: null },
      { name: "Prelude", series: null },
      { name: "Ridgeline", series: null },
      { name: "S2000", series: null },
      { name: "Shuttle", series: null },
      { name: "Stream", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Hummer",
    models: [
      { name: "H1", series: null },
      { name: "H2", series: null },
      { name: "H3", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Hyundai",
    models: [
      { name: "Accent", series: null },
      { name: "Atos", series: null },
      { name: "Azera", series: null },
      { name: "Coupe", series: null },
      { name: "Elantra", series: null },
      { name: "Excel", series: null },
      { name: "Galloper", series: null },
      { name: "Genesis", series: null },
      { name: "Getz", series: null },
      { name: "Grandeur", series: null },
      { name: "Grand Santa Fe", series: null },
      { name: "H-1", series: null },
      { name: "H 100", series: null },
      { name: "H-1 Starex", series: null },
      { name: "H 200", series: null },
      { name: "H350", series: null },
      { name: "i10", series: null },
      { name: "i20", series: null },
      { name: "i30", series: null },
      { name: "i40", series: null },
      { name: "i50", series: null },
      { name: "IONIQ", series: null },
      { name: "ix20", series: null },
      { name: "ix35", series: null },
      { name: "ix55", series: null },
      { name: "Kona", series: null },
      { name: "Lantra", series: null },
      { name: "Matrix", series: null },
      { name: "Nexo", series: null },
      { name: "Pony", series: null },
      { name: "Santa Fe", series: null },
      { name: "Santamo", series: null },
      { name: "S-Coupe", series: null },
      { name: "Sonata", series: null },
      { name: "Terracan", series: null },
      { name: "Trajet", series: null },
      { name: "Tucson", series: null },
      { name: "Veloster", series: null },
      { name: "Veracruz", series: null },
      { name: "XG 30", series: null },
      { name: "XG 350", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Infiniti",
    models: [
      { name: "EX30", series: null },
      { name: "EX35", series: null },
      { name: "EX37", series: null },
      { name: "FX", series: null },
      { name: "G35", series: null },
      { name: "G37", series: null },
      { name: "M30", series: null },
      { name: "M35", series: null },
      { name: "M37", series: null },
      { name: "Q30", series: null },
      { name: "Q45", series: null },
      { name: "Q50", series: null },
      { name: "Q60", series: null },
      { name: "Q70", series: null },
      { name: "QX30", series: null },
      { name: "QX50", series: null },
      { name: "QX56", series: null },
      { name: "QX70", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Isuzu",
    models: [
      { name: "Campo", series: null },
      { name: "D-Max", series: null },
      { name: "Gemini", series: null },
      { name: "Midi", series: null },
      { name: "PICK UP", series: null },
      { name: "Trooper", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Iveco",
    models: [
      { name: "Massif", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Jaguar",
    models: [
      { name: "Daimler", series: null },
      { name: "E-Pace", series: null },
      { name: "E-Type", series: null },
      { name: "F-Pace", series: null },
      { name: "F-Type", series: null },
      { name: "I-Pace", series: null },
      { name: "MK II", series: null },
      { name: "S-Type", series: null },
      { name: "XE", series: null },
      { name: "XF", series: null },
      { name: "XJ", series: null },
      { name: "XJ12", series: null },
      { name: "XJ40", series: null },
      { name: "XJ6", series: null },
      { name: "XJ8", series: null },
      { name: "XJR", series: null },
      { name: "XJS", series: null },
      { name: "XJSC", series: null },
      { name: "XK", series: null },
      { name: "XK8", series: null },
      { name: "XKR", series: null },
      { name: "X-Type", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Jeep",
    models: [
      { name: "Cherokee", series: null },
      { name: "CJ", series: null },
      { name: "Comanche", series: null },
      { name: "Commander", series: null },
      { name: "Compass", series: null },
      { name: "Grand Cherokee", series: null },
      { name: "Patriot", series: null },
      { name: "Renegade", series: null },
      { name: "Wagoneer", series: null },
      { name: "Willys", series: null },
      { name: "Wrangler", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Kia",
    models: [
      { name: "Besta", series: null },
      { name: "Borrego", series: null },
      { name: "Carens", series: null },
      { name: "Carnival", series: null },
      { name: "cee'd / Ceed", series: null },
      { name: "cee'd Sportswagon", series: null },
      { name: "Cerato", series: null },
      { name: "Clarus", series: null },
      { name: "Elan", series: null },
      { name: "Joice", series: null },
      { name: "K2500", series: null },
      { name: "K2700", series: null },
      { name: "Leo", series: null },
      { name: "Magentis", series: null },
      { name: "Mentor", series: null },
      { name: "Mini", series: null },
      { name: "Niro", series: null },
      { name: "Opirus", series: null },
      { name: "Optima", series: null },
      { name: "Picanto", series: null },
      { name: "Pregio", series: null },
      { name: "Pride", series: null },
      { name: "pro_cee'd / ProCeed", series: null },
      { name: "Retona", series: null },
      { name: "Rio", series: null },
      { name: "Roadster", series: null },
      { name: "Rocsta", series: null },
      { name: "Sephia", series: null },
      { name: "Shuma", series: null },
      { name: "Sorento", series: null },
      { name: "Soul", series: null },
      { name: "Sportage", series: null },
      { name: "Stinger", series: null },
      { name: "Stonic", series: null },
      { name: "Venga", series: null },
      { name: "XCeed", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Koenigsegg",
    models: [
      { name: "Agera", series: null },
      { name: "CCR", series: null },
      { name: "CCXR", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "KTM",
    models: [
      { name: "X-BOW", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lada",
    models: [
      { name: "110", series: null },
      { name: "111", series: null },
      { name: "112", series: null },
      { name: "1200", series: null },
      { name: "2107", series: null },
      { name: "2110", series: null },
      { name: "2111", series: null },
      { name: "2112", series: null },
      { name: "Aleko", series: null },
      { name: "Forma", series: null },
      { name: "Granta", series: null },
      { name: "Kalina", series: null },
      { name: "Niva", series: null },
      { name: "Nova", series: null },
      { name: "Priora", series: null },
      { name: "Samara", series: null },
      { name: "Taiga", series: null },
      { name: "Urban", series: null },
      { name: "Vesta", series: null },
      { name: "X-Ray", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lamborghini",
    models: [
      { name: "Aventador", series: null },
      { name: "Countach", series: null },
      { name: "Diablo", series: null },
      { name: "Espada", series: null },
      { name: "Gallardo", series: null },
      { name: "Huracán", series: null },
      { name: "Jalpa", series: null },
      { name: "LM", series: null },
      { name: "Miura", series: null },
      { name: "Murciélago", series: null },
      { name: "Urraco", series: null },
      { name: "Urus", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lancia",
    models: [
      { name: "Beta", series: null },
      { name: "Dedra", series: null },
      { name: "Delta", series: null },
      { name: "Flaminia", series: null },
      { name: "Flavia", series: null },
      { name: "Fulvia", series: null },
      { name: "Gamma", series: null },
      { name: "Kappa", series: null },
      { name: "Lybra", series: null },
      { name: "MUSA", series: null },
      { name: "Phedra", series: null },
      { name: "Prisma", series: null },
      { name: "Stratos", series: null },
      { name: "Thema", series: null },
      { name: "Thesis", series: null },
      { name: "Voyager", series: null },
      { name: "Ypsilon", series: null },
      { name: "Zeta", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Land Rover",
    models: [
      { name: "Defender", series: null },
      { name: "Discovery", series: null },
      { name: "Discovery Sport", series: null },
      { name: "Freelander", series: null },
      { name: "Range Rover", series: null },
      { name: "Range Rover Evoque", series: null },
      { name: "Range Rover Sport", series: null },
      { name: "Range Rover Velar", series: null },
      { name: "Serie I", series: null },
      { name: "Serie II", series: null },
      { name: "Serie III", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Landwind",
    models: [
      { name: "CV-9", series: null },
      { name: "S", series: null },
      { name: "SC2", series: null },
      { name: "SC4", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lexus",
    models: [
      { name: "CT 200h", series: null },
      { name: "ES 300", series: "ES Series" },
      { name: "ES 330", series: "ES Series" },
      { name: "ES 350", series: "ES Series" },
      { name: "GS 250", series: "GS Series" },
      { name: "GS 300", series: "GS Series" },
      { name: "GS 350", series: "GS Series" },
      { name: "GS 430", series: "GS Series" },
      { name: "GS 450", series: "GS Series" },
      { name: "GS 460", series: "GS Series" },
      { name: "GS F", series: "GS Series" },
      { name: "GX 470", series: null },
      { name: "IS 200", series: "IS Series" },
      { name: "IS 220", series: "IS Series" },
      { name: "IS 250", series: "IS Series" },
      { name: "IS 300", series: "IS Series" },
      { name: "IS 350", series: "IS Series" },
      { name: "IS-F", series: "IS Series" },
      { name: "LC 500", series: null },
      { name: "LC 500h", series: null },
      { name: "LFA", series: null },
      { name: "LS 400", series: "LS Series" },
      { name: "LS 430", series: "LS Series" },
      { name: "LS 460", series: "LS Series" },
      { name: "LS 500", series: "LS Series" },
      { name: "LS 600", series: "LS Series" },
      { name: "LX 470", series: "LX Series" },
      { name: "LX 570", series: "LX Series" },
      { name: "NX 200", series: "NX Series" },
      { name: "NX 300", series: "NX Series" },
      { name: "RC 200", series: "RC Series" },
      { name: "RC 300", series: "RC Series" },
      { name: "RC 350", series: "RC Series" },
      { name: "RC F", series: "RC Series" },
      { name: "RX 200", series: "RX Series" },
      { name: "RX 300", series: "RX Series" },
      { name: "RX 330", series: "RX Series" },
      { name: "RX 350", series: "RX Series" },
      { name: "RX 400", series: "RX Series" },
      { name: "RX 450", series: "RX Series" },
      { name: "SC 400", series: null },
      { name: "SC 430", series: null },
      { name: "UX", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Ligier",
    models: [
      { name: "Ambra", series: null },
      { name: "Be Sun", series: null },
      { name: "JS 50", series: null },
      { name: "JS 50 L", series: null },
      { name: "JS RC", series: null },
      { name: "Nova", series: null },
      { name: "Optima", series: null },
      { name: "X - Too", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lincoln",
    models: [
      { name: "Aviator", series: null },
      { name: "Continental", series: null },
      { name: "LS", series: null },
      { name: "Mark", series: null },
      { name: "Navigator", series: null },
      { name: "Town Car", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Lotus",
    models: [
      { name: "340 R", series: null },
      { name: "Cortina", series: null },
      { name: "Elan", series: null },
      { name: "Elise", series: null },
      { name: "Elite", series: null },
      { name: "Esprit", series: null },
      { name: "Europa", series: null },
      { name: "Evora", series: null },
      { name: "Excel", series: null },
      { name: "Exige", series: null },
      { name: "Super Seven", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Mahindra", models: [{ name: "Other", series: null }] },
  {
    name: "Maserati",
    models: [
      { name: "222", series: null },
      { name: "224", series: null },
      { name: "228", series: null },
      { name: "3200", series: null },
      { name: "418", series: null },
      { name: "420", series: null },
      { name: "4200", series: null },
      { name: "422", series: null },
      { name: "424", series: null },
      { name: "430", series: null },
      { name: "Biturbo", series: null },
      { name: "Ghibli", series: null },
      { name: "GranCabrio", series: null },
      { name: "Gransport", series: null },
      { name: "Granturismo", series: null },
      { name: "Indy", series: null },
      { name: "Karif", series: null },
      { name: "Levante", series: null },
      { name: "MC12", series: null },
      { name: "Merak", series: null },
      { name: "Quattroporte", series: null },
      { name: "Shamal", series: null },
      { name: "Spyder", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Maybach",
    models: [
      { name: "57", series: null },
      { name: "62", series: null },
      { name: "Pullman", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Mazda",
    models: [
      { name: "121", series: null },
      { name: "2", series: null },
      { name: "3", series: null },
      { name: "323", series: null },
      { name: "5", series: null },
      { name: "6", series: null },
      { name: "626", series: null },
      { name: "929", series: null },
      { name: "Bongo", series: null },
      { name: "B series", series: null },
      { name: "BT-50", series: null },
      { name: "CX-3", series: null },
      { name: "CX-30", series: null },
      { name: "CX-5", series: null },
      { name: "CX-7", series: null },
      { name: "CX-9", series: null },
      { name: "Demio", series: null },
      { name: "E series", series: null },
      { name: "Millenia", series: null },
      { name: "MPV", series: null },
      { name: "MX-3", series: null },
      { name: "MX-5", series: null },
      { name: "MX-6", series: null },
      { name: "Premacy", series: null },
      { name: "Protege", series: null },
      { name: "RX-6", series: null },
      { name: "RX-7", series: null },
      { name: "RX-8", series: null },
      { name: "Tribute", series: null },
      { name: "Xedos", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "McLaren",
    models: [
      { name: "540C", series: null },
      { name: "570GT", series: null },
      { name: "570S", series: null },
      { name: "650S", series: null },
      { name: "650S Coupé", series: null },
      { name: "650S Spider", series: null },
      { name: "675LT", series: null },
      { name: "675LT Spider", series: null },
      { name: "720S", series: null },
      { name: "GT", series: null },
      { name: "MP4-12C", series: null },
      { name: "P1", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Mercedes-Benz",
    models: [
      { name: "190", series: null },
      { name: "200", series: null },
      { name: "220", series: null },
      { name: "230", series: null },
      { name: "240", series: null },
      { name: "250", series: null },
      { name: "260", series: null },
      { name: "270", series: null },
      { name: "280", series: null },
      { name: "290", series: null },
      { name: "300", series: null },
      { name: "320", series: null },
      { name: "350", series: null },
      { name: "380", series: null },
      { name: "400", series: null },
      { name: "416", series: null },
      { name: "420", series: null },
      { name: "450", series: null },
      { name: "500", series: null },
      { name: "560", series: null },
      { name: "600", series: null },
      { name: "A 140", series: "A-Class" },
      { name: "A 150", series: "A-Class" },
      { name: "A 160", series: "A-Class" },
      { name: "A 170", series: "A-Class" },
      { name: "A 180", series: "A-Class" },
      { name: "A 190", series: "A-Class" },
      { name: "A 200", series: "A-Class" },
      { name: "A 210", series: "A-Class" },
      { name: "A 220", series: "A-Class" },
      { name: "A 250", series: "A-Class" },
      { name: "A 35 AMG", series: "A-Class" },
      { name: "A 45 AMG", series: "A-Class" },
      { name: "B 150", series: "B-Class" },
      { name: "B 160", series: "B-Class" },
      { name: "B 170", series: "B-Class" },
      { name: "B 180", series: "B-Class" },
      { name: "B 200", series: "B-Class" },
      { name: "B 220", series: "B-Class" },
      { name: "B 250", series: "B-Class" },
      { name: "B Electric Drive", series: "B-Class" },
      { name: "C 160", series: "C-Class" },
      { name: "C 180", series: "C-Class" },
      { name: "C 200", series: "C-Class" },
      { name: "C 220", series: "C-Class" },
      { name: "C 230", series: "C-Class" },
      { name: "C 240", series: "C-Class" },
      { name: "C 250", series: "C-Class" },
      { name: "C 270", series: "C-Class" },
      { name: "C 280", series: "C-Class" },
      { name: "C 300", series: "C-Class" },
      { name: "C 30 AMG", series: "C-Class" },
      { name: "C 320", series: "C-Class" },
      { name: "C 32 AMG", series: "C-Class" },
      { name: "C 350", series: "C-Class" },
      { name: "C 36 AMG", series: "C-Class" },
      { name: "C 400", series: "C-Class" },
      { name: "C 43 AMG", series: "C-Class" },
      { name: "C 450 AMG", series: "C-Class" },
      { name: "C 55 AMG", series: "C-Class" },
      { name: "C 63 AMG", series: "C-Class" },
      { name: "CE 200", series: "CE-Class" },
      { name: "CE 220", series: "CE-Class" },
      { name: "CE 230", series: "CE-Class" },
      { name: "CE 280", series: "CE-Class" },
      { name: "CE 300", series: "CE-Class" },
      { name: "CE 320", series: "CE-Class" },
      { name: "Citan", series: null },
      { name: "CLA 180", series: "CLA-Class" },
      { name: "CLA 180 Shooting Brake", series: "CLA-Class" },
      { name: "CLA 200", series: "CLA-Class" },
      { name: "CLA 200 Shooting Brake", series: "CLA-Class" },
      { name: "CLA 220", series: "CLA-Class" },
      { name: "CLA 220 Shooting Brake", series: "CLA-Class" },
      { name: "CLA 250", series: "CLA-Class" },
      { name: "CLA 250 Shooting Brake", series: "CLA-Class" },
      { name: "CLA 35 AMG", series: "CLA-Class" },
      { name: "CLA 45 AMG", series: "CLA-Class" },
      { name: "CLA 45 AMG Shooting Brake", series: "CLA-Class" },
      { name: "CLA Shooting Brake", series: "CLA-Class" },
      { name: "CLC 160", series: "CLC-Class" },
      { name: "CLC 180", series: "CLC-Class" },
      { name: "CLC 200", series: "CLC-Class" },
      { name: "CLC 220", series: "CLC-Class" },
      { name: "CLC 230", series: "CLC-Class" },
      { name: "CLC 250", series: "CLC-Class" },
      { name: "CLC 350", series: "CLC-Class" },
      { name: "CL 160", series: "CL-Class" },
      { name: "CL 180", series: "CL-Class" },
      { name: "CL 200", series: "CL-Class" },
      { name: "CL 220", series: "CL-Class" },
      { name: "CL 230", series: "CL-Class" },
      { name: "CL 320", series: "CL-Class" },
      { name: "CL 420", series: "CL-Class" },
      { name: "CL 500", series: "CL-Class" },
      { name: "CL 55 AMG", series: "CL-Class" },
      { name: "CL 600", series: "CL-Class" },
      { name: "CL 63 AMG", series: "CL-Class" },
      { name: "CL 65 AMG", series: "CL-Class" },
      { name: "CLK 200", series: "CLK-Class" },
      { name: "CLK 220", series: "CLK-Class" },
      { name: "CLK 230", series: "CLK-Class" },
      { name: "CLK 240", series: "CLK-Class" },
      { name: "CLK 270", series: "CLK-Class" },
      { name: "CLK 280", series: "CLK-Class" },
      { name: "CLK 320", series: "CLK-Class" },
      { name: "CLK 350", series: "CLK-Class" },
      { name: "CLK 430", series: "CLK-Class" },
      { name: "CLK 500", series: "CLK-Class" },
      { name: "CLK 55 AMG", series: "CLK-Class" },
      { name: "CLK 63 AMG", series: "CLK-Class" },
      { name: "CLS 220", series: "CLS-Class" },
      { name: "CLS 220 Shooting Brake", series: "CLS-Class" },
      { name: "CLS 250", series: "CLS-Class" },
      { name: "CLS 250 Shooting Brake", series: "CLS-Class" },
      { name: "CLS 280", series: "CLS-Class" },
      { name: "CLS 300", series: "CLS-Class" },
      { name: "CLS 320", series: "CLS-Class" },
      { name: "CLS 350", series: "CLS-Class" },
      { name: "CLS 350 Shooting Brake", series: "CLS-Class" },
      { name: "CLS 400", series: "CLS-Class" },
      { name: "CLS 400 Shooting Brake", series: "CLS-Class" },
      { name: "CLS 450", series: "CLS-Class" },
      { name: "CLS 500", series: "CLS-Class" },
      { name: "CLS 500 Shooting Brake", series: "CLS-Class" },
      { name: "CLS 53 AMG", series: "CLS-Class" },
      { name: "CLS 55 AMG", series: "CLS-Class" },
      { name: "CLS 63 AMG", series: "CLS-Class" },
      { name: "CLS 63 AMG Shooting Brake", series: "CLS-Class" },
      { name: "CLS Shooting Brake", series: "CLS-Class" },
      { name: "E 200", series: "E-Class" },
      { name: "E 220", series: "E-Class" },
      { name: "E 230", series: "E-Class" },
      { name: "E 240", series: "E-Class" },
      { name: "E 250", series: "E-Class" },
      { name: "E 260", series: "E-Class" },
      { name: "E 270", series: "E-Class" },
      { name: "E 280", series: "E-Class" },
      { name: "E 290", series: "E-Class" },
      { name: "E 300", series: "E-Class" },
      { name: "E 320", series: "E-Class" },
      { name: "E 350", series: "E-Class" },
      { name: "E 36 AMG", series: "E-Class" },
      { name: "E 400", series: "E-Class" },
      { name: "E 420", series: "E-Class" },
      { name: "E 430", series: "E-Class" },
      { name: "E 43 AMG", series: "E-Class" },
      { name: "E 450", series: "E-Class" },
      { name: "E 50", series: "E-Class" },
      { name: "E 500", series: "E-Class" },
      { name: "E 53 AMG", series: "E-Class" },
      { name: "E 55 AMG", series: "E-Class" },
      { name: "E 60 AMG", series: "E-Class" },
      { name: "E 63 AMG", series: "E-Class" },
      { name: "EQC", series: null },
      { name: "G 230", series: "G-Class" },
      { name: "G 240", series: "G-Class" },
      { name: "G 250", series: "G-Class" },
      { name: "G 270", series: "G-Class" },
      { name: "G 280", series: "G-Class" },
      { name: "G 290", series: "G-Class" },
      { name: "G 300", series: "G-Class" },
      { name: "G 320", series: "G-Class" },
      { name: "G 350", series: "G-Class" },
      { name: "G 400", series: "G-Class" },
      { name: "G 500", series: "G-Class" },
      { name: "G 55 AMG", series: "G-Class" },
      { name: "G 63 AMG", series: "G-Class" },
      { name: "G 65 AMG", series: "G-Class" },
      { name: "GLA 180", series: "GLA-Class" },
      { name: "GLA 200", series: "GLA-Class" },
      { name: "GLA 220", series: "GLA-Class" },
      { name: "GLA 250", series: "GLA-Class" },
      { name: "GLA 45 AMG", series: "GLA-Class" },
      { name: "GLB 180", series: "GLB-Class" },
      { name: "GLB 200", series: "GLB-Class" },
      { name: "GLB 220", series: "GLB-Class" },
      { name: "GLB 250", series: "GLB-Class" },
      { name: "GLC 200", series: "GLC-Class" },
      { name: "GLC 220", series: "GLC-Class" },
      { name: "GLC 250", series: "GLC-Class" },
      { name: "GLC 300", series: "GLC-Class" },
      { name: "GLC 350", series: "GLC-Class" },
      { name: "GLC 400", series: "GLC-Class" },
      { name: "GLC 43 AMG", series: "GLC-Class" },
      { name: "GLC 63 AMG", series: "GLC-Class" },
      { name: "GL 320", series: "GL-Class" },
      { name: "GL 350", series: "GL-Class" },
      { name: "GL 400", series: "GL-Class" },
      { name: "GL 420", series: "GL-Class" },
      { name: "GL 450", series: "GL-Class" },
      { name: "GL 500", series: "GL-Class" },
      { name: "GL 55 AMG", series: "GL-Class" },
      { name: "GL 63 AMG", series: "GL-Class" },
      { name: "GLE 250", series: "GLE-Class" },
      { name: "GLE 300", series: "GLE-Class" },
      { name: "GLE 350", series: "GLE-Class" },
      { name: "GLE 400", series: "GLE-Class" },
      { name: "GLE 43 AMG", series: "GLE-Class" },
      { name: "GLE 450", series: "GLE-Class" },
      { name: "GLE 500", series: "GLE-Class" },
      { name: "GLE 53 AMG", series: "GLE-Class" },
      { name: "GLE 63 AMG", series: "GLE-Class" },
      { name: "GLK 200", series: "GLK-Class" },
      { name: "GLK 220", series: "GLK-Class" },
      { name: "GLK 250", series: "GLK-Class" },
      { name: "GLK 280", series: "GLK-Class" },
      { name: "GLK 300", series: "GLK-Class" },
      { name: "GLK 320", series: "GLK-Class" },
      { name: "GLK 350", series: "GLK-Class" },
      { name: "GLS 350", series: "GLS-Class" },
      { name: "GLS 400", series: "GLS-Class" },
      { name: "GLS 500", series: "GLS-Class" },
      { name: "GLS 63", series: "GLS-Class" },
      { name: "AMG GT", series: "GT-Class" },
      { name: "AMG GT C", series: "GT-Class" },
      { name: "AMG GT R", series: "GT-Class" },
      { name: "AMG GT S", series: "GT-Class" },
      { name: "MB 100", series: null },
      { name: "ML 230", series: "ML-Class" },
      { name: "ML 250", series: "ML-Class" },
      { name: "ML 270", series: "ML-Class" },
      { name: "ML 280", series: "ML-Class" },
      { name: "ML 300", series: "ML-Class" },
      { name: "ML 320", series: "ML-Class" },
      { name: "ML 350", series: "ML-Class" },
      { name: "ML 400", series: "ML-Class" },
      { name: "ML 420", series: "ML-Class" },
      { name: "ML 430", series: "ML-Class" },
      { name: "ML 450", series: "ML-Class" },
      { name: "ML 500", series: "ML-Class" },
      { name: "ML 55 AMG", series: "ML-Class" },
      { name: "ML 63 AMG", series: "ML-Class" },
      { name: "R 280", series: "R-Class" },
      { name: "R 300", series: "R-Class" },
      { name: "R 320", series: "R-Class" },
      { name: "R 350", series: "R-Class" },
      { name: "R 500", series: "R-Class" },
      { name: "R 63 AMG", series: "R-Class" },
      { name: "S 250", series: "S-Class" },
      { name: "S 260", series: "S-Class" },
      { name: "S 280", series: "S-Class" },
      { name: "S 300", series: "S-Class" },
      { name: "S 320", series: "S-Class" },
      { name: "S 350", series: "S-Class" },
      { name: "S 400", series: "S-Class" },
      { name: "S 420", series: "S-Class" },
      { name: "S 430", series: "S-Class" },
      { name: "S 450", series: "S-Class" },
      { name: "S 500", series: "S-Class" },
      { name: "S 55", series: "S-Class" },
      { name: "S 550", series: "S-Class" },
      { name: "S 560", series: "S-Class" },
      { name: "S 600", series: "S-Class" },
      { name: "S 63 AMG", series: "S-Class" },
      { name: "S 650", series: "S-Class" },
      { name: "S 65 AMG", series: "S-Class" },
      { name: "SLC 180", series: "SLC-Class" },
      { name: "SLC 200", series: "SLC-Class" },
      { name: "SLC 250", series: "SLC-Class" },
      { name: "SLC 280", series: "SLC-Class" },
      { name: "SLC 300", series: "SLC-Class" },
      { name: "SLC 43 AMG", series: "SLC-Class" },
      { name: "SL 280", series: "SL-Class" },
      { name: "SL 300", series: "SL-Class" },
      { name: "SL 320", series: "SL-Class" },
      { name: "SL 350", series: "SL-Class" },
      { name: "SL 380", series: "SL-Class" },
      { name: "SL 400", series: "SL-Class" },
      { name: "SL 420", series: "SL-Class" },
      { name: "SL 450", series: "SL-Class" },
      { name: "SL 500", series: "SL-Class" },
      { name: "SL 55 AMG", series: "SL-Class" },
      { name: "SL 560", series: "SL-Class" },
      { name: "SL 600", series: "SL-Class" },
      { name: "SL 60 AMG", series: "SL-Class" },
      { name: "SL 63 AMG", series: "SL-Class" },
      { name: "SL 65 AMG", series: "SL-Class" },
      { name: "SL 70 AMG", series: "SL-Class" },
      { name: "SL 73 AMG", series: "SL-Class" },
      { name: "SLK 200", series: "SLK-Class" },
      { name: "SLK 230", series: "SLK-Class" },
      { name: "SLK 250", series: "SLK-Class" },
      { name: "SLK 280", series: "SLK-Class" },
      { name: "SLK 300", series: "SLK-Class" },
      { name: "SLK 320", series: "SLK-Class" },
      { name: "SLK 32 AMG", series: "SLK-Class" },
      { name: "SLK 350", series: "SLK-Class" },
      { name: "SLK 55 AMG", series: "SLK-Class" },
      { name: "SLR", series: null },
      { name: "SLS AMG", series: null },
      { name: "Sprinter", series: null },
      { name: "Vaneo", series: null },
      { name: "Vario", series: null },
      { name: "V 200", series: "V-Class" },
      { name: "V 220", series: "V-Class" },
      { name: "V 230", series: "V-Class" },
      { name: "V 250", series: "V-Class" },
      { name: "V 280", series: "V-Class" },
      { name: "V 300", series: "V-Class" },
      { name: "Viano", series: null },
      { name: "Vito", series: null },
      { name: "X 220", series: "X-Class" },
      { name: "X 250", series: "X-Class" },
      { name: "X 350", series: "X-Class" },
      { name: "Other", series: null },
    ],
  },
  {
    name: "MG",
    models: [
      { name: "MGA", series: null },
      { name: "MGB", series: null },
      { name: "MGF", series: null },
      { name: "Midget", series: null },
      { name: "Montego", series: null },
      { name: "TD", series: null },
      { name: "TF", series: null },
      { name: "ZR", series: null },
      { name: "ZS", series: null },
      { name: "ZT", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Microcar",
    models: [
      { name: "DUÈ", series: null },
      { name: "Flex", series: null },
      { name: "M.Go", series: null },
      { name: "M-8", series: null },
      { name: "MC1", series: null },
      { name: "MC2", series: null },
      { name: "Virgo", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "MINI",
    models: [
      { name: "Cooper Cabrio", series: "Cabrio Series" },
      { name: "Cooper D Cabrio", series: "Cabrio Series" },
      { name: "Cooper S Cabrio", series: "Cabrio Series" },
      { name: "Cooper SD Cabrio", series: "Cabrio Series" },
      { name: "John Cooper Works Cabrio", series: "Cabrio Series" },
      { name: "One Cabrio", series: "Cabrio Series" },
      { name: "Cooper Clubman", series: "Clubman Series" },
      { name: "Cooper D Clubman", series: "Clubman Series" },
      { name: "Cooper S Clubman", series: "Clubman Series" },
      { name: "Cooper SD Clubman", series: "Clubman Series" },
      { name: "John Cooper Works Clubman", series: "Clubman Series" },
      { name: "One Clubman", series: "Clubman Series" },
      { name: "One D Clubman", series: "Clubman Series" },
      { name: "Clubvan", series: null },
      { name: "Cooper Countryman", series: "Countryman Series" },
      { name: "Cooper D Countryman", series: "Countryman Series" },
      { name: "Cooper S Countryman", series: "Countryman Series" },
      { name: "Cooper SD Countryman", series: "Countryman Series" },
      { name: "John Cooper Works Countryman", series: "Countryman Series" },
      { name: "One Countryman", series: "Countryman Series" },
      { name: "One D Countryman", series: "Countryman Series" },
      { name: "Cooper Coupé", series: "Coupe Series" },
      { name: "Cooper S Coupé", series: "Coupe Series" },
      { name: "Cooper SD Coupé", series: "Coupe Series" },
      { name: "John Cooper Works Coupé", series: "Coupe Series" },
      { name: "1000", series: "MINI" },
      { name: "1300", series: "MINI" },
      { name: "Cooper", series: "MINI" },
      { name: "Cooper D", series: "MINI" },
      { name: "Cooper S", series: "MINI" },
      { name: "Cooper SD", series: "MINI" },
      { name: "John Cooper Works", series: "MINI" },
      { name: "ONE", series: "MINI" },
      { name: "One D", series: "MINI" },
      { name: "One First", series: "MINI" },
      { name: "Cooper D Paceman", series: "Paceman Series" },
      { name: "Cooper Paceman", series: "Paceman Series" },
      { name: "Cooper SD Paceman", series: "Paceman Series" },
      { name: "Cooper S Paceman", series: "Paceman Series" },
      { name: "John Cooper Works Paceman", series: "Paceman Series" },
      { name: "Cooper Roadster", series: "Roadster Series" },
      { name: "Cooper SD Roadster", series: "Roadster Series" },
      { name: "Cooper S Roadster", series: "Roadster Series" },
      { name: "John Cooper Works Roadster", series: "Roadster Series" },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Mitsubishi",
    models: [
      { name: "3000 GT", series: null },
      { name: "ASX", series: null },
      { name: "Canter", series: null },
      { name: "Carisma", series: null },
      { name: "Colt", series: null },
      { name: "Cordia", series: null },
      { name: "Cosmos", series: null },
      { name: "Diamante", series: null },
      { name: "Eclipse", series: null },
      { name: "Eclipse Cross", series: null },
      { name: "Galant", series: null },
      { name: "Galloper", series: null },
      { name: "Grandis", series: null },
      { name: "i-MiEV", series: null },
      { name: "L200", series: null },
      { name: "L300", series: null },
      { name: "L400", series: null },
      { name: "Lancer", series: null },
      { name: "Mirage", series: null },
      { name: "Montero", series: null },
      { name: "Outlander", series: null },
      { name: "Pajero", series: null },
      { name: "Pajero Pinin", series: null },
      { name: "Pick-up", series: null },
      { name: "Plug-in Hybrid Outlander", series: null },
      { name: "Santamo", series: null },
      { name: "Sapporo", series: null },
      { name: "Sigma", series: null },
      { name: "Space Gear", series: null },
      { name: "Space Runner", series: null },
      { name: "Space Star", series: null },
      { name: "Space Wagon", series: null },
      { name: "Starion", series: null },
      { name: "Tredia", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Morgan",
    models: [
      { name: "3 Wheeler", series: null },
      { name: "4/4", series: null },
      { name: "Aero 8", series: null },
      { name: "Plus 4", series: null },
      { name: "Plus 8", series: null },
      { name: "Roadster", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Nissan",
    models: [
      { name: "100 NX", series: null },
      { name: "200 SX", series: null },
      { name: "240 SX", series: null },
      { name: "280 ZX", series: null },
      { name: "300 ZX", series: null },
      { name: "350Z", series: null },
      { name: "370Z", series: null },
      { name: "Almera", series: null },
      { name: "Almera Tino", series: null },
      { name: "Altima", series: null },
      { name: "Armada", series: null },
      { name: "Bluebird", series: null },
      { name: "Cabstar", series: null },
      { name: "Cargo", series: null },
      { name: "Cherry", series: null },
      { name: "Cube", series: null },
      { name: "e-NV200", series: null },
      { name: "Evalia", series: null },
      { name: "Frontier", series: null },
      { name: "GT-R", series: null },
      { name: "Interstar", series: null },
      { name: "Juke", series: null },
      { name: "King Cab", series: null },
      { name: "Kubistar", series: null },
      { name: "Laurel", series: null },
      { name: "Leaf", series: null },
      { name: "Maxima", series: null },
      { name: "Micra", series: null },
      { name: "Murano", series: null },
      { name: "Navara", series: null },
      { name: "Note", series: null },
      { name: "NP 300", series: null },
      { name: "NV200", series: null },
      { name: "NV250", series: null },
      { name: "NV300", series: null },
      { name: "NV400", series: null },
      { name: "Pathfinder", series: null },
      { name: "Patrol", series: null },
      { name: "PickUp", series: null },
      { name: "Pixo", series: null },
      { name: "Prairie", series: null },
      { name: "Primastar", series: null },
      { name: "Primera", series: null },
      { name: "Pulsar", series: null },
      { name: "Qashqai", series: null },
      { name: "Qashqai+2", series: null },
      { name: "Quest", series: null },
      { name: "Sentra", series: null },
      { name: "Serena", series: null },
      { name: "Silvia", series: null },
      { name: "Skyline", series: null },
      { name: "Sunny", series: null },
      { name: "Terrano", series: null },
      { name: "Tiida", series: null },
      { name: "Titan", series: null },
      { name: "Trade", series: null },
      { name: "Urvan", series: null },
      { name: "Vanette", series: null },
      { name: "X-Trail", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "NSU", models: [{ name: "Other", series: null }] },
  {
    name: "Oldsmobile",
    models: [
      { name: "Bravada", series: null },
      { name: "Custom Cruiser", series: null },
      { name: "Cutlass", series: null },
      { name: "Delta 88", series: null },
      { name: "Silhouette", series: null },
      { name: "Supreme", series: null },
      { name: "Toronado", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Opel",
    models: [
      { name: "Adam", series: null },
      { name: "Agila", series: null },
      { name: "Ampera", series: null },
      { name: "Ampera-e", series: null },
      { name: "Antara", series: null },
      { name: "Arena", series: null },
      { name: "Ascona", series: null },
      { name: "Astra", series: null },
      { name: "Calibra", series: null },
      { name: "Campo", series: null },
      { name: "Cascada", series: null },
      { name: "Cavalier", series: null },
      { name: "Combo", series: null },
      { name: "Commodore", series: null },
      { name: "Corsa", series: null },
      { name: "Crossland X", series: null },
      { name: "Diplomat", series: null },
      { name: "Frontera", series: null },
      { name: "Grandland X", series: null },
      { name: "GT", series: null },
      { name: "Insignia", series: null },
      { name: "Insignia CT", series: null },
      { name: "Kadett", series: null },
      { name: "Karl", series: null },
      { name: "Manta", series: null },
      { name: "Meriva", series: null },
      { name: "Mokka", series: null },
      { name: "Mokka X", series: null },
      { name: "Monterey", series: null },
      { name: "Monza", series: null },
      { name: "Movano", series: null },
      { name: "Nova", series: null },
      { name: "Omega", series: null },
      { name: "Pick Up Sportscap", series: null },
      { name: "Rekord", series: null },
      { name: "Senator", series: null },
      { name: "Signum", series: null },
      { name: "Sintra", series: null },
      { name: "Speedster", series: null },
      { name: "Tigra", series: null },
      { name: "Vectra", series: null },
      { name: "Vivaro", series: null },
      { name: "Zafira", series: null },
      { name: "Zafira Life", series: null },
      { name: "Zafira Tourer", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Pagani",
    models: [
      { name: "Huayra", series: null },
      { name: "Zonda", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Peugeot",
    models: [
      { name: "1007", series: null },
      { name: "104", series: null },
      { name: "106", series: null },
      { name: "107", series: null },
      { name: "108", series: null },
      { name: "2008", series: null },
      { name: "204", series: null },
      { name: "205", series: null },
      { name: "206", series: null },
      { name: "207", series: null },
      { name: "208", series: null },
      { name: "3008", series: null },
      { name: "301", series: null },
      { name: "304", series: null },
      { name: "305", series: null },
      { name: "306", series: null },
      { name: "307", series: null },
      { name: "308", series: null },
      { name: "309", series: null },
      { name: "4007", series: null },
      { name: "4008", series: null },
      { name: "404", series: null },
      { name: "405", series: null },
      { name: "406", series: null },
      { name: "407", series: null },
      { name: "5008", series: null },
      { name: "504", series: null },
      { name: "505", series: null },
      { name: "508", series: null },
      { name: "604", series: null },
      { name: "605", series: null },
      { name: "607", series: null },
      { name: "806", series: null },
      { name: "807", series: null },
      { name: "Bipper", series: null },
      { name: "Bipper Tepee", series: null },
      { name: "Boxer", series: null },
      { name: "Expert", series: null },
      { name: "Expert Tepee", series: null },
      { name: "iOn", series: null },
      { name: "J5", series: null },
      { name: "Partner", series: null },
      { name: "Partner Tepee", series: null },
      { name: "RCZ", series: null },
      { name: "Rifter", series: null },
      { name: "TePee", series: null },
      { name: "Traveller", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Piaggio",
    models: [
      { name: "APE", series: null },
      { name: "APE TM", series: null },
      { name: "Porter", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Plymouth",
    models: [
      { name: "Prowler", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Polestar",
    models: [
      { name: "1", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Pontiac",
    models: [
      { name: "6000", series: null },
      { name: "Bonneville", series: null },
      { name: "Fiero", series: null },
      { name: "Firebird", series: null },
      { name: "G6", series: null },
      { name: "Grand-Am", series: null },
      { name: "Grand-Prix", series: null },
      { name: "GTO", series: null },
      { name: "Montana", series: null },
      { name: "Solstice", series: null },
      { name: "Sunbird", series: null },
      { name: "Sunfire", series: null },
      { name: "Targa", series: null },
      { name: "Trans Am", series: null },
      { name: "Trans Sport", series: null },
      { name: "Vibe", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Porsche",
    models: [
      { name: "356", series: null },
      { name: "912", series: null },
      { name: "914", series: null },
      { name: "918", series: null },
      { name: "924", series: null },
      { name: "928", series: null },
      { name: "944", series: null },
      { name: "959", series: null },
      { name: "962", series: null },
      { name: "968", series: null },
      { name: "Boxster", series: null },
      { name: "Carrera GT", series: null },
      { name: "Cayenne", series: null },
      { name: "Cayman", series: null },
      { name: "Macan", series: null },
      { name: "Panamera", series: null },
      { name: "911", series: "Series 911" },
      { name: "930", series: "Series 911" },
      { name: "964", series: "Series 911" },
      { name: "991", series: "Series 911" },
      { name: "992", series: "Series 911" },
      { name: "993", series: "Series 911" },
      { name: "996", series: "Series 911" },
      { name: "997", series: "Series 911" },
      { name: "Taycan", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Proton",
    models: [
      { name: "300 Serie", series: null },
      { name: "400 Serie", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Renault",
    models: [
      { name: "Alaskan", series: null },
      { name: "Alpine A110", series: null },
      { name: "Alpine A310", series: null },
      { name: "Alpine V6", series: null },
      { name: "Avantime", series: null },
      { name: "Captur", series: null },
      { name: "Clio", series: null },
      { name: "Coupe", series: null },
      { name: "Espace", series: null },
      { name: "Express", series: null },
      { name: "Fluence", series: null },
      { name: "Fuego", series: null },
      { name: "Grand Espace", series: null },
      { name: "Grand Modus", series: null },
      { name: "Grand Scenic", series: null },
      { name: "Kadjar", series: null },
      { name: "Kangoo", series: null },
      { name: "Koleos", series: null },
      { name: "Laguna", series: null },
      { name: "Latitude", series: null },
      { name: "Mascott", series: null },
      { name: "Master", series: null },
      { name: "Megane", series: null },
      { name: "Modus", series: null },
      { name: "P 1400", series: null },
      { name: "R 11", series: null },
      { name: "R 14", series: null },
      { name: "R 18", series: null },
      { name: "R 19", series: null },
      { name: "R 20", series: null },
      { name: "R 21", series: null },
      { name: "R 25", series: null },
      { name: "R 30", series: null },
      { name: "R 4", series: null },
      { name: "R 5", series: null },
      { name: "R 6", series: null },
      { name: "R 9", series: null },
      { name: "Rapid", series: null },
      { name: "Safrane", series: null },
      { name: "Scenic", series: null },
      { name: "Spider", series: null },
      { name: "Talisman", series: null },
      { name: "Trafic", series: null },
      { name: "Twingo", series: null },
      { name: "Twizy", series: null },
      { name: "Vel Satis", series: null },
      { name: "Wind", series: null },
      { name: "ZOE", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Rolls-Royce",
    models: [
      { name: "Corniche", series: null },
      { name: "Cullinan", series: null },
      { name: "Dawn", series: null },
      { name: "Flying Spur", series: null },
      { name: "Ghost", series: null },
      { name: "Park Ward", series: null },
      { name: "Phantom", series: null },
      { name: "Silver Cloud", series: null },
      { name: "Silver Dawn", series: null },
      { name: "Silver Seraph", series: null },
      { name: "Silver Shadow", series: null },
      { name: "Silver Spirit", series: null },
      { name: "Silver Spur", series: null },
      { name: "Wraith", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Rover",
    models: [
      { name: "100", series: null },
      { name: "111", series: null },
      { name: "114", series: null },
      { name: "115", series: null },
      { name: "200", series: null },
      { name: "213", series: null },
      { name: "214", series: null },
      { name: "216", series: null },
      { name: "218", series: null },
      { name: "220", series: null },
      { name: "25", series: null },
      { name: "400", series: null },
      { name: "414", series: null },
      { name: "416", series: null },
      { name: "418", series: null },
      { name: "420", series: null },
      { name: "45", series: null },
      { name: "600", series: null },
      { name: "618", series: null },
      { name: "620", series: null },
      { name: "623", series: null },
      { name: "75", series: null },
      { name: "800", series: null },
      { name: "820", series: null },
      { name: "825", series: null },
      { name: "827", series: null },
      { name: "City Rover", series: null },
      { name: "Metro", series: null },
      { name: "Montego", series: null },
      { name: "SD", series: null },
      { name: "Streetwise", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Ruf", models: [{ name: "Other", series: null }] },
  {
    name: "Saab",
    models: [
      { name: "90", series: null },
      { name: "900", series: null },
      { name: "9000", series: null },
      { name: "9-3", series: null },
      { name: "9-4X", series: null },
      { name: "9-5", series: null },
      { name: "96", series: null },
      { name: "9-7X", series: null },
      { name: "99", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Santana", models: [{ name: "Other", series: null }] },
  {
    name: "Seat",
    models: [
      { name: "Alhambra", series: null },
      { name: "Altea", series: null },
      { name: "Arona", series: null },
      { name: "Arosa", series: null },
      { name: "Ateca", series: null },
      { name: "Cordoba", series: null },
      { name: "Exeo", series: null },
      { name: "Ibiza", series: null },
      { name: "Inca", series: null },
      { name: "Leon", series: null },
      { name: "Malaga", series: null },
      { name: "Marbella", series: null },
      { name: "Mii", series: null },
      { name: "Tarraco", series: null },
      { name: "Terra", series: null },
      { name: "Toledo", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Skoda",
    models: [
      { name: "105", series: null },
      { name: "120", series: null },
      { name: "130", series: null },
      { name: "135", series: null },
      { name: "Citigo", series: null },
      { name: "Fabia", series: null },
      { name: "Favorit", series: null },
      { name: "Felicia", series: null },
      { name: "Forman", series: null },
      { name: "Kamiq", series: null },
      { name: "Karoq", series: null },
      { name: "Kodiaq", series: null },
      { name: "Octavia", series: null },
      { name: "Pick-up", series: null },
      { name: "Praktik", series: null },
      { name: "Rapid", series: null },
      { name: "Roomster", series: null },
      { name: "Scala", series: null },
      { name: "Superb", series: null },
      { name: "Yeti", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Smart",
    models: [
      { name: "Crossblade", series: null },
      { name: "ForFour", series: null },
      { name: "ForTwo", series: null },
      { name: "Roadster", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "speedART", models: [{ name: "Other", series: null }] },
  {
    name: "Spyker",
    models: [
      { name: "C8", series: null },
      { name: "C8 AILERON", series: null },
      { name: "C8 DOUBLE 12 S", series: null },
      { name: "C8 LAVIOLETTE SWB", series: null },
      { name: "C8 SPYDER SWB", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Ssangyong",
    models: [
      { name: "Actyon", series: null },
      { name: "Family", series: null },
      { name: "Korando", series: null },
      { name: "Kyron", series: null },
      { name: "MUSSO", series: null },
      { name: "REXTON", series: null },
      { name: "Rodius", series: null },
      { name: "Tivoli", series: null },
      { name: "XLV", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Subaru",
    models: [
      { name: "B9 Tribeca", series: null },
      { name: "Baja", series: null },
      { name: "BRZ", series: null },
      { name: "Forester", series: null },
      { name: "Impreza", series: null },
      { name: "Justy", series: null },
      { name: "Legacy", series: null },
      { name: "Levorg", series: null },
      { name: "Libero", series: null },
      { name: "Outback", series: null },
      { name: "SVX", series: null },
      { name: "Trezia", series: null },
      { name: "Tribeca", series: null },
      { name: "Vivio", series: null },
      { name: "WRX STI", series: null },
      { name: "XT", series: null },
      { name: "XV", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Suzuki",
    models: [
      { name: "Alto", series: null },
      { name: "Baleno", series: null },
      { name: "Cappuccino", series: null },
      { name: "Carry", series: null },
      { name: "Celerio", series: null },
      { name: "Grand Vitara", series: null },
      { name: "Ignis", series: null },
      { name: "iK-2", series: null },
      { name: "Jimny", series: null },
      { name: "Kizashi", series: null },
      { name: "Liana", series: null },
      { name: "LJ", series: null },
      { name: "SJ Samurai", series: null },
      { name: "Splash", series: null },
      { name: "Super-Carry", series: null },
      { name: "Swift", series: null },
      { name: "SX4", series: null },
      { name: "SX4 S-Cross", series: null },
      { name: "Vitara", series: null },
      { name: "Wagon R+", series: null },
      { name: "X-90", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Talbot",
    models: [
      { name: "Horizon", series: null },
      { name: "Samba", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Tata",
    models: [
      { name: "Indica", series: null },
      { name: "Indigo", series: null },
      { name: "Nano", series: null },
      { name: "Safari", series: null },
      { name: "Sumo", series: null },
      { name: "Telcoline", series: null },
      { name: "Telcosport", series: null },
      { name: "Xenon", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "TECHART", models: [{ name: "Other", series: null }] },
  {
    name: "Tesla",
    models: [
      { name: "Model 3", series: null },
      { name: "Model S", series: null },
      { name: "Model X", series: null },
      { name: "Roadster", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Toyota",
    models: [
      { name: "4-Runner", series: null },
      { name: "Alphard", series: null },
      { name: "Auris", series: null },
      { name: "Auris Touring Sports", series: null },
      { name: "Avalon", series: null },
      { name: "Avensis", series: null },
      { name: "Avensis Verso", series: null },
      { name: "Aygo", series: null },
      { name: "Camry", series: null },
      { name: "Carina", series: null },
      { name: "Celica", series: null },
      { name: "C-HR", series: null },
      { name: "Corolla", series: null },
      { name: "Corolla Verso", series: null },
      { name: "Cressida", series: null },
      { name: "Crown", series: null },
      { name: "Dyna", series: null },
      { name: "FCV", series: null },
      { name: "FJ", series: null },
      { name: "Fortuner", series: null },
      { name: "GT86", series: null },
      { name: "Hiace", series: null },
      { name: "Highlander", series: null },
      { name: "Hilux", series: null },
      { name: "IQ", series: null },
      { name: "Land Cruiser", series: null },
      { name: "Lite-Ace", series: null },
      { name: "Matrix", series: null },
      { name: "Mirai", series: null },
      { name: "MR 2", series: null },
      { name: "Paseo", series: null },
      { name: "Picnic", series: null },
      { name: "Previa", series: null },
      { name: "Prius", series: null },
      { name: "Prius+", series: null },
      { name: "Proace (Verso)", series: null },
      { name: "RAV 4", series: null },
      { name: "Sequoia", series: null },
      { name: "Sienna", series: null },
      { name: "Starlet", series: null },
      { name: "Supra", series: null },
      { name: "Tacoma", series: null },
      { name: "Tercel", series: null },
      { name: "Tundra", series: null },
      { name: "Urban Cruiser", series: null },
      { name: "Verso", series: null },
      { name: "Verso-S", series: null },
      { name: "Yaris", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Trabant",
    models: [
      { name: "601", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Triumph",
    models: [
      { name: "Dolomite", series: null },
      { name: "Moss", series: null },
      { name: "Spitfire", series: null },
      { name: "TR3", series: null },
      { name: "TR4", series: null },
      { name: "TR5", series: null },
      { name: "TR6", series: null },
      { name: "TR7", series: null },
      { name: "TR8", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "TVR",
    models: [
      { name: "Chimaera", series: null },
      { name: "Griffith", series: null },
      { name: "Tuscan", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Volkswagen",
    models: [
      { name: "181", series: null },
      { name: "Amarok", series: null },
      { name: "Arteon", series: null },
      { name: "Beetle", series: null },
      { name: "Beetle", series: null },
      { name: "Bora", series: null },
      { name: "Buggy", series: null },
      { name: "Caddy", series: null },
      { name: "CC", series: null },
      { name: "Corrado", series: null },
      { name: "Crafter", series: null },
      { name: "Eos", series: null },
      { name: "Fox", series: null },
      { name: "Golf", series: "Golf" },
      { name: "Golf Plus", series: "Golf" },
      { name: "Golf Sportsvan", series: "Golf" },
      { name: "Iltis", series: null },
      { name: "Jetta", series: null },
      { name: "Karmann Ghia", series: null },
      { name: "LT", series: null },
      { name: "Lupo", series: null },
      { name: "New Beetle", series: null },
      { name: "Passat", series: "Passat" },
      { name: "Passat Alltrack", series: "Passat" },
      { name: "Passat CC", series: "Passat" },
      { name: "Passat Variant", series: "Passat" },
      { name: "Phaeton", series: null },
      { name: "Polo", series: null },
      { name: "Routan", series: null },
      { name: "Santana", series: null },
      { name: "Scirocco", series: null },
      { name: "Sharan", series: null },
      { name: "T1", series: null },
      { name: "T2", series: null },
      { name: "T3 Caravelle", series: "T3" },
      { name: "T3 Kombi", series: "T3" },
      { name: "T3 Multivan", series: "T3" },
      { name: "T3 other", series: "T3" },
      { name: "T4 California", series: "T4" },
      { name: "T4 Caravelle", series: "T4" },
      { name: "T4 Kombi", series: "T4" },
      { name: "T4 Multivan", series: "T4" },
      { name: "T4 other", series: "T4" },
      { name: "T5 California", series: "T5" },
      { name: "T5 Caravelle", series: "T5" },
      { name: "T5 Kombi", series: "T5" },
      { name: "T5 Multivan", series: "T5" },
      { name: "T5 other", series: "T5" },
      { name: "T5 Shuttle", series: "T5" },
      { name: "T5 Transporter", series: "T5" },
      { name: "T6 California", series: "T6" },
      { name: "T6 Caravelle", series: "T6" },
      { name: "T6 Kombi", series: "T6" },
      { name: "T6 Multivan", series: "T6" },
      { name: "T6 other", series: "T6" },
      { name: "T6 Transporter", series: "T6" },
      { name: "Taro", series: null },
      { name: "T-Cross", series: null },
      { name: "Tiguan", series: null },
      { name: "Tiguan Allspace", series: null },
      { name: "Touareg", series: null },
      { name: "Touran", series: null },
      { name: "T-Roc", series: null },
      { name: "up!", series: null },
      { name: "Vento", series: null },
      { name: "XL1", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Volvo",
    models: [
      { name: "240", series: null },
      { name: "244", series: null },
      { name: "245", series: null },
      { name: "262", series: null },
      { name: "264", series: null },
      { name: "340", series: null },
      { name: "360", series: null },
      { name: "440", series: null },
      { name: "460", series: null },
      { name: "480", series: null },
      { name: "740", series: null },
      { name: "744", series: null },
      { name: "745", series: null },
      { name: "760", series: null },
      { name: "780", series: null },
      { name: "850", series: null },
      { name: "855", series: null },
      { name: "940", series: null },
      { name: "944", series: null },
      { name: "945", series: null },
      { name: "960", series: null },
      { name: "965", series: null },
      { name: "Amazon", series: null },
      { name: "C30", series: null },
      { name: "C70", series: null },
      { name: "Polar", series: null },
      { name: "S40", series: null },
      { name: "S60", series: null },
      { name: "S60 Cross Country", series: null },
      { name: "S70", series: null },
      { name: "S80", series: null },
      { name: "S90", series: null },
      { name: "V40", series: null },
      { name: "V40 Cross Country", series: null },
      { name: "V50", series: null },
      { name: "V60", series: null },
      { name: "V60 Cross Country", series: null },
      { name: "V70", series: null },
      { name: "V90", series: null },
      { name: "V90 Cross Country", series: null },
      { name: "XC40", series: null },
      { name: "XC60", series: null },
      { name: "XC70", series: null },
      { name: "XC90", series: null },
      { name: "Other", series: null },
    ],
  },
  {
    name: "Wartburg",
    models: [
      { name: "311", series: null },
      { name: "353", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Westfield", models: [{ name: "Other", series: null }] },
  {
    name: "Wiesmann",
    models: [
      { name: "MF 25", series: null },
      { name: "MF 28", series: null },
      { name: "MF 3", series: null },
      { name: "MF 30", series: null },
      { name: "MF 35", series: null },
      { name: "MF 4", series: null },
      { name: "MF 5", series: null },
      { name: "Other", series: null },
    ],
  },
  { name: "Other", models: [{ name: "Other", series: null }] },
];
export const types = [
  { value: "Hatchback", label: "Hatchback" },
  { value: "Convertable", label: "Convertable" },
  { value: "Coupe", label: "Coupe" },
  { value: "Crossover", label: "Crossover" },
  { value: "Pickup", label: "Pickup" },
  { value: "Sedan", label: "Sedan" },
  { value: "SUV", label: "SUV" },
  { value: "Truck", label: "Truck" },
  { value: "Van", label: "van" },
  { value: "Minivan", label: "Minivan" },
  { value: "Wagon", label: "Wagon" },
  { value: "2 Wheeler", label: "2 Wheeler" },
];
export const fuels = [
  { value: "Petrol", label: "Petrol" },
  { value: "Diesel", label: "Diesel" },
  { value: "Electric", label: "Electric" },
  { value: "LP Gas", label: "LP Gas" },
  { value: "Hybrid", label: "Hybrid" },
];
export const transmission = [
  { value: "Manual", label: "Manual" },
  { value: "Automatic", label: "Automatic" },
];
export const usages = [
  {
    value: "1",
    label: "Light Motor Vehicle (Small Sedans, Wagons etc)",
  },
  { value: "2", label: "Heavy Motor Vehicle (Lorry, Haulage etc)" },
  { value: "3", label: "Domestic Trailer" },
  { value: "4", label: "Commercial Trailer" },
  { value: "5", label: "Caravan" },
  { value: "6", label: "Taxi" },
  { value: "7", label: "Motorcycle" },
  { value: "8", label: "Omnibus or Commuter" },
  { value: "9", label: "School Bus" },
  { value: "10", label: "Staff Bus" },
  { value: "13", label: "Tractor / Fork Lift / Combine" },
  { value: "14", label: "Ambulance, Fire Engine, Hearse" },
  { value: "15", label: "Agricultural Implement" },
  {
    value: "16",
    label: "Contractor Plants & Equipment (Dozers, Graders etc)",
  },
];
export const usecases = [
  { value: "1", label: "Social, Domestic, Private Use" },
  { value: "2", label: "Business Use" },
  { value: "3", label: "For Hire" },
  { value: "4", label: "Driving School" },
];

export const years = () => {
  let options = [];
  for (let index = new Date().getFullYear(); index >= 1960; index--) {
    options.push({ value: index, label: index });
  }
  //console.log(options);
  return options;
};
