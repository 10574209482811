import { IconButton } from "@chakra-ui/button";
import { EditIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";

function ModelFragment({ model }) {
  return (
    <Flex align="center">
      <Text flex="1">{model.type_name}</Text>
      <Link to={"/admin/vehicletype/" + model.type_id}>
        <IconButton icon={<EditIcon />}></IconButton>
      </Link>
    </Flex>
  );
}

export default ModelFragment;
