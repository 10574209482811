import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";

import { Flex, Grid, Container, GridItem } from "@chakra-ui/layout";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";
import Select from "react-select";

import { makes, types, years, fuels, usages, usecases } from "../utils/Data";
import { Button } from "@chakra-ui/button";
import axiosInstance from "../../helpers/axios";
import { useToast } from "@chakra-ui/toast";

function ThirdPartyStep1({ step, setStep, data, setData }) {
  const [makeOptions, setMakeOptions] = useState([]);
  const [vmakes] = useState(makes);
  const [models, setModels] = useState([]);

  const [vtypes, setVTypes] = useState([]);
  const [vuses, setVuses] = useState([]);
  const [vloading, setVLoading] = useState(false);

  const toast = useToast();

  const [make, setMake] = useState(data?.vehicle?.make);
  const [model, setModel] = useState(data?.vehicle?.model);
  const [year, setYear] = useState(data?.vehicle?.year);
  const [mileage, setMileage] = useState(data?.vehicle?.mileage);
  const [fuel, setFuel] = useState(data?.vehicle?.fuel);
  const [body, setBody] = useState(data?.vehicle?.body);
  const [usage, setUsage] = useState(data?.vehicle?.usage);
  const [usecase, setUsecase] = useState(data?.vehicle?.usecases);
  const [regno, setRegNo] = useState(data?.vehicle?.regno);
  const [value, setValue] = useState(data?.vehicle?.value);

  useEffect(() => {
    let options = [];
    vmakes.map((make, index) => {
      options.push({ value: index, label: make.name, models: make.models });
      return null;
    });
    setMakeOptions(options);

    setVLoading(true);
    axiosInstance
      .get("/types")
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          setVTypes(res.data);
        }
        setVLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setVLoading(false);
      });
  }, [vmakes]);

  const validate = (values) => {
    const errors = {};

    if (!values.make) {
      errors.make = "Vehicle make is required";
    }
    if (!values.model) {
      errors.model = "Vehicle model is required";
    }
    if (!values.year) {
      errors.year = "Vehicle year is required";
    }
    if (!values.mileage) {
      errors.mileage = "Vehicle mileage is required";
    }
    if (!values.fuel) {
      errors.fuel = "Vehicle fuel type is required";
    }
    if (!values.body) {
      errors.body = "Vehicle body type is required";
    }
    if (!values.usage) {
      errors.usage = "Vehicle type is required";
    }
    if (!values.usecases) {
      errors.usecases = "Vehicle use is required";
    }
    if (!values.regno) {
      errors.regno = "Vehicle Registration No. is required";
    }
    if (!values.value) {
      errors.value =
        "Vehicle Registration No. is required will be used if you need Fire and Theft";
    }
    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      make: make,
      models: [],
      model: model,
      year: year,
      mileage: mileage,
      fuel: fuel,
      body: body,
      usage: usage,
      usecases: usecase,
      regno: regno,
      value: value,
    },
    validate,
    onSubmit: (values, actions) => {
      let carryOver = data;
      carryOver.vehicle = values;
      console.log(carryOver);
      setData(carryOver);
      setStep(step + 1);
      //submit(values, actions);
    },
  });

  return (
    <form style={{ marginTop: "50px" }} onSubmit={formik.handleSubmit}>
      <Container maxW="container.lg">
        <Grid
          gap={10}
          mb={10}
          templateColumns={{ base: "100%", md: "repeat(2, 1fr)" }}
        >
          <GridItem>
            <FormControl id="make" isInvalid={formik.errors.make}>
              <FormLabel>Make</FormLabel>
              <Select
                options={makeOptions}
                placeholder={"Select Make..."}
                value={make}
                onChange={(e) => {
                  setMake(e);
                  formik.values.make = e;
                  let options = [];
                  e.models.map((model, index) => {
                    let opt = "";
                    if (model.series) {
                      opt = " " + model.series;
                    }
                    options.push({
                      value: index,
                      label: model.name + opt,
                      model: model,
                    });
                    return null;
                  });
                  setModels(options);
                  formik.values.models = options;
                  setModel(null);
                  formik.errors.make = null;
                  formik.values.model = null;
                }}
              ></Select>

              <FormErrorMessage>{formik.errors.make}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="model" isInvalid={formik.errors.model}>
              <FormLabel>Model</FormLabel>
              <Select
                options={models}
                placeholder={"Select Model..."}
                value={model}
                background="#F7F7F7"
                onChange={(e) => {
                  setModel(e);
                  formik.values.model = e;
                  formik.errors.model = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.model}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="year" isInvalid={formik.errors.year}>
              <FormLabel>Year</FormLabel>
              <Select
                options={years()}
                placeholder={"Select Year..."}
                background="#F7F7F7"
                value={year}
                onChange={(e) => {
                  setYear(e);
                  formik.values.year = e;
                  formik.errors.year = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.year}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="mileage" isInvalid={formik.errors.mileage}>
              <FormLabel>Mileage</FormLabel>
              <Input
                placeholder="Vehicle Mileage"
                type="number"
                value={mileage}
                onChange={(e) => {
                  setMileage(e.target?.value);
                  formik.values.mileage = e.target?.value;
                  formik.errors.mileage = null;
                  console.log(formik.values.mileage);
                }}
              />
              <FormErrorMessage>{formik.errors.mileage}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="fuel" isInvalid={formik.errors.fuel}>
              <FormLabel>Fuel</FormLabel>
              <Select
                options={fuels}
                placeholder={"Select Fuel Type..."}
                background="#F7F7F7"
                value={fuel}
                onChange={(e) => {
                  setFuel(e);
                  formik.values.fuel = e;
                  formik.errors.fuel = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.fuel}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="body" isInvalid={formik.errors.body}>
              <FormLabel>Body Type</FormLabel>
              <Select
                options={types}
                placeholder={"Select Type..."}
                background="#F7F7F7"
                value={body}
                onChange={(e) => {
                  setBody(e);
                  formik.values.body = e;
                  formik.errors.body = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.body}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="usage" isInvalid={formik.errors.usage}>
              <FormLabel>Vehicle Type</FormLabel>
              <Select
                options={vtypes}
                placeholder={"Select Type..."}
                background="#F7F7F7"
                value={usage}
                isLoading={vloading}
                onChange={(e) => {
                  setUsage(e);
                  setVuses(e.uses);
                  setUsecase(null);
                  formik.values.usage = e;
                  formik.errors.usage = null;
                  formik.values.usecases = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.usage}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="usecases" isInvalid={formik.errors.usecases}>
              <FormLabel>Vehicle Use Type</FormLabel>
              <Select
                options={vuses}
                placeholder={"Select Type..."}
                background="#F7F7F7"
                value={usecase}
                onChange={(e) => {
                  setUsecase(e);
                  formik.values.usecases = e;
                  formik.errors.usecases = null;
                  //   console.log(e.clear());
                }}
              ></Select>
              <FormErrorMessage>{formik.errors.usecases}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="regno" isInvalid={formik.errors.regno}>
              <FormLabel>Registration Number</FormLabel>
              <Input
                placeholder="Vehicle Registration Number"
                type="text"
                value={regno}
                onChange={(e) => {
                  setRegNo(e.target?.value);
                  formik.values.regno = e.target?.value;
                  formik.errors.regno = null;
                  //console.log(formik.values.regno);
                }}
              />
              <FormErrorMessage>{formik.errors.regno}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="value" isInvalid={formik.errors.value}>
              <FormLabel>Vehicle Value</FormLabel>
              <InputGroup>
                <InputLeftAddon children="US$" />
                <Input
                  placeholder="Vehicle Value"
                  type="number"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target?.value);
                    formik.values.value = e.target?.value;
                    formik.errors.value = null;
                    //console.log(formik.values.regno);
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.value}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex justify="space-between" align="center">
          <Button
            type={"button"}
            disabled={step === 1}
            onClick={() => {
              setStep(step > 1 ? step - 1 : 1);
            }}
          >
            Previous
          </Button>
          <Button
            type={"submit"}
            onClick={() => {
              //setStep(step + 1);
            }}
          >
            Next
          </Button>
        </Flex>
      </Container>
    </form>
  );
}

export default ThirdPartyStep1;
