import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function AccentButtonCTA({to = "#",text = "Default",loading = false}) {
    return (
        <Link to={to}>
           <Button color="white" bg="accent.1"  _hover={{ bg: "accent.2" }} borderRadius="20px"  {...(loading && { isLoading: true })}>
               {text}
           </Button>
        </Link>
    )
}

export default AccentButtonCTA
