import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import {
  Badge,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/layout";
import { useFormik } from "formik";
import { Button } from "@chakra-ui/button";
import axiosInstance from "../../helpers/axios";
import { useToast } from "@chakra-ui/toast";

function SinglePricing({ use, setPricing, setPricings }) {
  const [loading, setLoading] = useState(false);
  const [pricinguse, setPricingUse] = useState(use);
  const toast = useToast();

  const [premium_3rdparty, setpremium_3rdparty] = useState(
    pricinguse?.pricing?.pricing_3rdparty_premium
  );
  const [premium_fire, setpremium_fire] = useState(
    pricinguse?.pricing?.pricing_fire_premium
  );
  const [premium_full, setpremium_full] = useState(
    pricinguse?.pricing?.pricing_full_premium
  );
  const [rta_3rdparty, setrta_3rdparty] = useState(
    pricinguse?.pricing?.pricing_3rdparty_rta
  );
  const [rta_fire, setrta_fire] = useState(
    pricinguse?.pricing?.pricing_fire_rta
  );
  const [rta_full, setrta_full] = useState(
    pricinguse?.pricing?.pricing_full_rta
  );
  const [stamp_3rdparty, setstamp_3rdparty] = useState(
    pricinguse?.pricing?.pricing_3rdparty_stamp
  );
  const [stamp_fire, setstamp_fire] = useState(
    pricinguse?.pricing?.pricing_fire_stamp
  );
  const [stamp_full, setstamp_full] = useState(
    pricinguse?.pricing?.pricing_full_stamp
  );

  const validate = (values) => {
    const errors = {};

    console.log(values);

    if (!values.premium_3rdparty || values.premium_3rdparty < 0.01) {
      errors.premium_3rdparty = "Premium value required";
    }
    if (!values.premium_fire || values.premium_fire < 0.01) {
      errors.premium_fire = "Premium value required";
    }
    if (!values.premium_full || values.premium_full < 0.01) {
      errors.premium_full = "Premium value required";
    }
    if (!values.rta_3rdparty || values.premium_full < 0.01) {
      errors.rta_3rdparty = "RTA value required";
    }
    if (!values.rta_fire || values.rta_fire < 0.01) {
      errors.rta_fire = "RTA value required";
    }
    if (!values.rta_full || values.rta_full < 0.01) {
      errors.rta_full = "RTA value required";
    }
    if (!values.stamp_3rdparty || values.stamp_3rdparty < 0.01) {
      errors.stamp_3rdparty = "Stamp Duty value required";
    }
    if (!values.stamp_fire || values.stamp_fire < 0.01) {
      errors.stamp_fire = "Stamp Duty value required";
    }
    if (!values.stamp_full || values.stamp_full < 0.01) {
      errors.stamp_full = "Stamp Duty value required";
    }

    return errors;
  };

  const submit = (values, actions) => {
    setLoading(true);

    axiosInstance
      .post("/updateuse", values)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          toast({
            description: res.message,
            status: "success",
            position: "top-right",
          });
          setPricing(res.pricing);
          setPricings(res.data);
          //setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(premium_3rdparty);

  const formik = useFormik({
    initialValues: {
      premium_3rdparty: premium_3rdparty,
      premium_fire: premium_fire,
      premium_full: premium_full,
      rta_3rdparty: rta_3rdparty,
      rta_fire: rta_fire,
      rta_full: rta_full,
      stamp_3rdparty: stamp_3rdparty,
      stamp_fire: stamp_fire,
      stamp_full: stamp_full,
      use_value: pricinguse,
    },
    validate,
    onSubmit: (values, actions) => {
      submit(values, actions);
    },
  });
  return (
    <form style={{ marginTop: "30px", marginBottom: "30px" }}>
      <Badge fontSize="1em">3rd Party</Badge>
      <Grid gap={5} templateColumns={{ base: "100%", md: "1fr 1fr 1fr" }}>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={
              formik.errors.premium_3rdparty && formik.touched.premium_3rdparty
            }
          >
            <FormLabel>3rd Party Premium</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="3rd Party Premium"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={premium_3rdparty}
              onChange={(e) => {
                setpremium_3rdparty(e.target.value);
                formik.values.premium_3rdparty = e.target.value;
                formik.errors.premium_3rdparty = null;
              }}
            />
            <FormErrorMessage>
              {formik.errors.premium_3rdparty}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={
              formik.errors.rta_3rdparty && formik.touched.rta_3rdparty
            }
          >
            <FormLabel>3rd Party RTA</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="3rd Party RTA"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={rta_3rdparty}
              onChange={(e) => {
                setrta_3rdparty(e.target.value);
                formik.values.rta_3rdparty = e.target.value;
                formik.errors.rta_3rdparty = null;
              }}
            />
            <FormErrorMessage>{formik.errors.rta_3rdparty}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={
              formik.errors.stamp_3rdparty && formik.touched.stamp_3rdparty
            }
          >
            <FormLabel>3rd Party Stamp Duty</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="3rd Party Stamp Duty"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={stamp_3rdparty}
              onChange={(e) => {
                setstamp_3rdparty(e.target.value);
                formik.values.stamp_3rdparty = e.target.value;
                formik.errors.stamp_3rdparty = null;
              }}
            />
            <FormErrorMessage>{formik.errors.stamp_3rdparty}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <Badge fontSize="1em" mb={3}>
        3rd Party + Fire & Theft
      </Badge>
      <Grid gap={5} templateColumns={{ base: "100%", md: "1fr 1fr 1fr" }}>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={
              formik.errors.premium_fire && formik.touched.premium_fire
            }
          >
            <FormLabel>Fire & Theft Premium %</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="3rd Party (Fire and Theft) Premium %"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={premium_fire}
              onChange={(e) => {
                setpremium_fire(e.target.value);
                formik.values.premium_fire = e.target.value;
                formik.errors.premium_fire = null;
              }}
            />
            <FormErrorMessage>{formik.errors.premium_fire}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={formik.errors.rta_fire && formik.touched.rta_fire}
          >
            <FormLabel>RTA Fire & Theft</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="RTA Fire & Theft"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={rta_fire}
              onChange={(e) => {
                setrta_fire(e.target.value);
                formik.values.rta_fire = e.target.value;
                formik.errors.rta_fire = null;
              }}
            />
            <FormErrorMessage>{formik.errors.rta_fire}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={formik.errors.stamp_fire && formik.touched.stamp_fire}
          >
            <FormLabel>Stamp Duty Fire & Theft</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="Fire & Theft Stamp Duty"
              type="number"
              step={0.01}
              min={0.01}
              disabled={loading}
              value={stamp_fire}
              onChange={(e) => {
                setstamp_fire(e.target.value);
                formik.values.stamp_fire = e.target.value;
                formik.errors.stamp_fire = null;
              }}
            />
            <FormErrorMessage>{formik.errors.stamp_fire}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <Badge fontSize="1em" mb={3}>
        Full Cover
      </Badge>
      <Grid gap={5} templateColumns={{ base: "100%", md: "1fr 1fr 1fr" }}>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={
              formik.errors.premium_full && formik.touched.premium_full
            }
          >
            <FormLabel>Premium % Full Cover </FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="Full Cover Premium % min 0.01 max 100%"
              type="number"
              min={0.01}
              max={100}
              step={0.01}
              disabled={loading}
              value={premium_full}
              onChange={(e) => {
                setpremium_full(e.target.value);
                formik.values.premium_full = e.target.value;
                formik.errors.premium_full = null;
              }}
            />
            <FormErrorMessage>{formik.errors.premium_full}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={formik.errors.rta_full && formik.touched.rta_full}
          >
            <FormLabel>RTA Full Cover</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="RTA Full Cover"
              type="number"
              min={0.01}
              max={100}
              step={0.01}
              disabled={loading}
              value={rta_full}
              onChange={(e) => {
                setrta_full(e.target.value);
                formik.values.rta_full = e.target.value;
                formik.errors.rta_full = null;
              }}
            />
            <FormErrorMessage>{formik.errors.rta_full}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl
            mb={10}
            isInvalid={formik.errors.stamp_full && formik.touched.stamp_full}
          >
            <FormLabel>Stamp Duty Full Cover</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="Full Cover Stamp Duty"
              type="number"
              min={0.01}
              max={100}
              step={0.01}
              disabled={loading}
              value={stamp_full}
              onChange={(e) => {
                setstamp_full(e.target.value);
                formik.values.stamp_full = e.target.value;
                formik.errors.stamp_full = null;
              }}
            />
            <FormErrorMessage>{formik.errors.stamp_full}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <Button
        colorScheme={pricinguse.pricing ? "green" : "red"}
        isLoading={loading}
        onClick={formik.handleSubmit}
      >
        {pricinguse.pricing ? "Update" : "Create"}
      </Button>
    </form>
  );
}

export default SinglePricing;
