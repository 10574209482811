import React from "react";
import { Link } from "react-router-dom";
import HeroSection from "../components/basic/HeroSection";
import AccentButtonCTA from "../components/utils/AccentButtonCTA";

import Slider from "react-slick";
import { Button } from "@chakra-ui/button";

function Home() {
  const settings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 15000,
    cssEase: "linear",
    infinite: true,
    arrows: false,
    touchMove: true,
    swipeToSlide: true,
    swipe: true,
    className: "testimonials-tesimonies",
  };

  return (
    <>
      <HeroSection />
      <section className="intro-section">
        <div className="container">
          <div className="section-title">
            <span>Avoid</span> constly repairs
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </section>
      <section className="products-section">
        <div className="products">
          <div className="product-item">
            <div className="product-card">
              <div className="product-top">
                <div className="product-title">3rd Party</div>
                <div className="product-subtitle">+ Fire & Theft</div>
              </div>
              <Link to="/insurance/3rd-party">Insure Now</Link>
            </div>
            <div className="product-triangle-container">
              <svg
                viewBox="0 0 130 158"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8701 101.5C10.8701 100.124 11.1341 99.04 11.6621 98.248C12.1981 97.456 13.0581 97.06 14.2421 97.06C15.4261 97.06 16.2821 97.456 16.8101 98.248C17.3461 99.04 17.6141 100.124 17.6141 101.5C17.6141 102.892 17.3461 103.984 16.8101 104.776C16.2821 105.568 15.4261 105.964 14.2421 105.964C13.0581 105.964 12.1981 105.568 11.6621 104.776C11.1341 103.984 10.8701 102.892 10.8701 101.5ZM15.5981 101.5C15.5981 100.692 15.5101 100.072 15.3341 99.64C15.1581 99.2 14.7941 98.98 14.2421 98.98C13.6901 98.98 13.3261 99.2 13.1501 99.64C12.9741 100.072 12.8861 100.692 12.8861 101.5C12.8861 102.044 12.9181 102.496 12.9821 102.856C13.0461 103.208 13.1741 103.496 13.3661 103.72C13.5661 103.936 13.8581 104.044 14.2421 104.044C14.6261 104.044 14.9141 103.936 15.1061 103.72C15.3061 103.496 15.4381 103.208 15.5021 102.856C15.5661 102.496 15.5981 102.044 15.5981 101.5ZM18.4822 99.148V97.24H21.8062V106H19.6702V99.148H18.4822Z"
                  fill="white"
                />
                <g filter="url(#filter0_d)">
                  <path
                    d="M99.0412 22.1181C112.568 25.1743 117.703 41.7347 108.279 51.9079L41.2049 124.314C30.0802 136.324 10 128.452 10 112.082V24.5207C10 12.9791 20.7091 4.41963 31.9669 6.96324L99.0412 22.1181Z"
                    fill="url(#paint0_linear)"
                  />
                </g>
                <g clipPath="url(#clip0)">
                  <path
                    d="M62.6472 45.707C62.1516 45.2114 61.3459 45.2114 60.8502 45.707L43.9584 62.5989L34.6889 53.332C34.1932 52.8364 33.3875 52.8364 32.8919 53.332C32.3962 53.8276 32.3962 54.6334 32.8919 55.129L43.0585 65.2956C43.3076 65.5421 43.6329 65.6668 43.9582 65.6668C44.2836 65.6668 44.6089 65.5423 44.8555 65.2956L62.6471 47.504C63.1429 47.0083 63.1429 46.2026 62.6472 45.707Z"
                    fill="white"
                  />
                  <path
                    d="M72.2801 32.6784L46.8635 25.0534C46.6246 24.9822 46.373 24.9822 46.134 25.0534L20.7174 32.6784C20.181 32.8385 19.8125 33.3341 19.8125 33.8959V61.8543C19.8125 73.0935 36.9484 83.0899 46.1239 85.9441C46.2459 85.9823 46.373 86 46.5 86C46.627 86 46.7541 85.9823 46.8761 85.9441C56.0516 83.0873 73.1875 73.0934 73.1875 61.8541V33.8959C73.1875 33.3341 72.819 32.841 72.2801 32.6784ZM70.6459 61.8541C70.6459 70.7779 56.5066 80.1618 46.5 83.3973C36.4935 80.1618 22.3541 70.7779 22.3541 61.8541V34.8414L46.5 27.5976L70.6459 34.8414V61.8541V61.8541Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="0.513153"
                    width="123.088"
                    height="143.605"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear"
                    x1="69.75"
                    y1="2"
                    x2="10"
                    y2="125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#DDFF0E" />
                    <stop offset="1" stopColor="#B8CE33" />
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect
                      width="61"
                      height="61"
                      fill="white"
                      transform="translate(16 25)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="product-item">
            <div className="product-card">
              <div className="product-top">
                <div className="product-title">Comprehensive</div>
              </div>
              <Link to="#">Insure Now</Link>
            </div>
            <div className="product-triangle-container">
              <svg
                viewBox="0 0 130 158"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8701 101.5C10.8701 100.124 11.1341 99.04 11.6621 98.248C12.1981 97.456 13.0581 97.06 14.2421 97.06C15.4261 97.06 16.2821 97.456 16.8101 98.248C17.3461 99.04 17.6141 100.124 17.6141 101.5C17.6141 102.892 17.3461 103.984 16.8101 104.776C16.2821 105.568 15.4261 105.964 14.2421 105.964C13.0581 105.964 12.1981 105.568 11.6621 104.776C11.1341 103.984 10.8701 102.892 10.8701 101.5ZM15.5981 101.5C15.5981 100.692 15.5101 100.072 15.3341 99.64C15.1581 99.2 14.7941 98.98 14.2421 98.98C13.6901 98.98 13.3261 99.2 13.1501 99.64C12.9741 100.072 12.8861 100.692 12.8861 101.5C12.8861 102.044 12.9181 102.496 12.9821 102.856C13.0461 103.208 13.1741 103.496 13.3661 103.72C13.5661 103.936 13.8581 104.044 14.2421 104.044C14.6261 104.044 14.9141 103.936 15.1061 103.72C15.3061 103.496 15.4381 103.208 15.5021 102.856C15.5661 102.496 15.5981 102.044 15.5981 101.5ZM18.4822 99.148V97.24H21.8062V106H19.6702V99.148H18.4822Z"
                  fill="white"
                />
                <g filter="url(#filter0_d)">
                  <path
                    d="M99.0412 22.1181C112.568 25.1743 117.703 41.7347 108.279 51.9079L41.2049 124.314C30.0802 136.324 10 128.452 10 112.082V24.5207C10 12.9791 20.7091 4.41963 31.9669 6.96324L99.0412 22.1181Z"
                    fill="url(#paint0_linear2)"
                  />
                </g>
                <g clipPath="url(#clip0)">
                  <path
                    d="M62.6472 45.707C62.1516 45.2114 61.3459 45.2114 60.8502 45.707L43.9584 62.5989L34.6889 53.332C34.1932 52.8364 33.3875 52.8364 32.8919 53.332C32.3962 53.8276 32.3962 54.6334 32.8919 55.129L43.0585 65.2956C43.3076 65.5421 43.6329 65.6668 43.9582 65.6668C44.2836 65.6668 44.6089 65.5423 44.8555 65.2956L62.6471 47.504C63.1429 47.0083 63.1429 46.2026 62.6472 45.707Z"
                    fill="white"
                  />
                  <path
                    d="M72.2801 32.6784L46.8635 25.0534C46.6246 24.9822 46.373 24.9822 46.134 25.0534L20.7174 32.6784C20.181 32.8385 19.8125 33.3341 19.8125 33.8959V61.8543C19.8125 73.0935 36.9484 83.0899 46.1239 85.9441C46.2459 85.9823 46.373 86 46.5 86C46.627 86 46.7541 85.9823 46.8761 85.9441C56.0516 83.0873 73.1875 73.0934 73.1875 61.8541V33.8959C73.1875 33.3341 72.819 32.841 72.2801 32.6784ZM70.6459 61.8541C70.6459 70.7779 56.5066 80.1618 46.5 83.3973C36.4935 80.1618 22.3541 70.7779 22.3541 61.8541V34.8414L46.5 27.5976L70.6459 34.8414V61.8541V61.8541Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="0.513153"
                    width="123.088"
                    height="143.605"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear2"
                    x1="69.75"
                    y1="2"
                    x2="10"
                    y2="125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0ED4FF" />
                    <stop offset="1" stopColor="#33B2CE" />
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect
                      width="61"
                      height="61"
                      fill="white"
                      transform="translate(16 25)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="product-item">
            <div className="product-card">
              <div className="product-top">
                <div className="product-title">Travel Insurance</div>
              </div>
              <Link to="#">Insure Now</Link>
            </div>
            <div className="product-triangle-container">
              <svg
                viewBox="0 0 130 158"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8701 101.5C10.8701 100.124 11.1341 99.04 11.6621 98.248C12.1981 97.456 13.0581 97.06 14.2421 97.06C15.4261 97.06 16.2821 97.456 16.8101 98.248C17.3461 99.04 17.6141 100.124 17.6141 101.5C17.6141 102.892 17.3461 103.984 16.8101 104.776C16.2821 105.568 15.4261 105.964 14.2421 105.964C13.0581 105.964 12.1981 105.568 11.6621 104.776C11.1341 103.984 10.8701 102.892 10.8701 101.5ZM15.5981 101.5C15.5981 100.692 15.5101 100.072 15.3341 99.64C15.1581 99.2 14.7941 98.98 14.2421 98.98C13.6901 98.98 13.3261 99.2 13.1501 99.64C12.9741 100.072 12.8861 100.692 12.8861 101.5C12.8861 102.044 12.9181 102.496 12.9821 102.856C13.0461 103.208 13.1741 103.496 13.3661 103.72C13.5661 103.936 13.8581 104.044 14.2421 104.044C14.6261 104.044 14.9141 103.936 15.1061 103.72C15.3061 103.496 15.4381 103.208 15.5021 102.856C15.5661 102.496 15.5981 102.044 15.5981 101.5ZM18.4822 99.148V97.24H21.8062V106H19.6702V99.148H18.4822Z"
                  fill="white"
                />
                <g filter="url(#filter0_d)">
                  <path
                    d="M99.0412 22.1181C112.568 25.1743 117.703 41.7347 108.279 51.9079L41.2049 124.314C30.0802 136.324 10 128.452 10 112.082V24.5207C10 12.9791 20.7091 4.41963 31.9669 6.96324L99.0412 22.1181Z"
                    fill="url(#paint0_linear3)"
                  />
                </g>
                <g clipPath="url(#clip0)">
                  <path
                    d="M62.6472 45.707C62.1516 45.2114 61.3459 45.2114 60.8502 45.707L43.9584 62.5989L34.6889 53.332C34.1932 52.8364 33.3875 52.8364 32.8919 53.332C32.3962 53.8276 32.3962 54.6334 32.8919 55.129L43.0585 65.2956C43.3076 65.5421 43.6329 65.6668 43.9582 65.6668C44.2836 65.6668 44.6089 65.5423 44.8555 65.2956L62.6471 47.504C63.1429 47.0083 63.1429 46.2026 62.6472 45.707Z"
                    fill="white"
                  />
                  <path
                    d="M72.2801 32.6784L46.8635 25.0534C46.6246 24.9822 46.373 24.9822 46.134 25.0534L20.7174 32.6784C20.181 32.8385 19.8125 33.3341 19.8125 33.8959V61.8543C19.8125 73.0935 36.9484 83.0899 46.1239 85.9441C46.2459 85.9823 46.373 86 46.5 86C46.627 86 46.7541 85.9823 46.8761 85.9441C56.0516 83.0873 73.1875 73.0934 73.1875 61.8541V33.8959C73.1875 33.3341 72.819 32.841 72.2801 32.6784ZM70.6459 61.8541C70.6459 70.7779 56.5066 80.1618 46.5 83.3973C36.4935 80.1618 22.3541 70.7779 22.3541 61.8541V34.8414L46.5 27.5976L70.6459 34.8414V61.8541V61.8541Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="0.513153"
                    width="123.088"
                    height="143.605"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear3"
                    x1="69.75"
                    y1="2"
                    x2="10"
                    y2="125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#72FF5B" />
                    <stop offset="1" stopColor="#64CE33" />
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect
                      width="61"
                      height="61"
                      fill="white"
                      transform="translate(16 25)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="product-item">
            <div className="product-card">
              <div className="product-top">
                <div className="product-title">Hospital Cash Plan</div>
                <div className="product-subtitle">Coming Soon</div>
              </div>
              <Link to="#">Insure Now</Link>
            </div>
            <div className="product-triangle-container">
              <svg
                viewBox="0 0 130 158"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8701 101.5C10.8701 100.124 11.1341 99.04 11.6621 98.248C12.1981 97.456 13.0581 97.06 14.2421 97.06C15.4261 97.06 16.2821 97.456 16.8101 98.248C17.3461 99.04 17.6141 100.124 17.6141 101.5C17.6141 102.892 17.3461 103.984 16.8101 104.776C16.2821 105.568 15.4261 105.964 14.2421 105.964C13.0581 105.964 12.1981 105.568 11.6621 104.776C11.1341 103.984 10.8701 102.892 10.8701 101.5ZM15.5981 101.5C15.5981 100.692 15.5101 100.072 15.3341 99.64C15.1581 99.2 14.7941 98.98 14.2421 98.98C13.6901 98.98 13.3261 99.2 13.1501 99.64C12.9741 100.072 12.8861 100.692 12.8861 101.5C12.8861 102.044 12.9181 102.496 12.9821 102.856C13.0461 103.208 13.1741 103.496 13.3661 103.72C13.5661 103.936 13.8581 104.044 14.2421 104.044C14.6261 104.044 14.9141 103.936 15.1061 103.72C15.3061 103.496 15.4381 103.208 15.5021 102.856C15.5661 102.496 15.5981 102.044 15.5981 101.5ZM18.4822 99.148V97.24H21.8062V106H19.6702V99.148H18.4822Z"
                  fill="white"
                />
                <g filter="url(#filter0_d)">
                  <path
                    d="M99.0412 22.1181C112.568 25.1743 117.703 41.7347 108.279 51.9079L41.2049 124.314C30.0802 136.324 10 128.452 10 112.082V24.5207C10 12.9791 20.7091 4.41963 31.9669 6.96324L99.0412 22.1181Z"
                    fill="url(#paint0_linear4)"
                  />
                </g>
                <g clipPath="url(#clip0)">
                  <path
                    d="M62.6472 45.707C62.1516 45.2114 61.3459 45.2114 60.8502 45.707L43.9584 62.5989L34.6889 53.332C34.1932 52.8364 33.3875 52.8364 32.8919 53.332C32.3962 53.8276 32.3962 54.6334 32.8919 55.129L43.0585 65.2956C43.3076 65.5421 43.6329 65.6668 43.9582 65.6668C44.2836 65.6668 44.6089 65.5423 44.8555 65.2956L62.6471 47.504C63.1429 47.0083 63.1429 46.2026 62.6472 45.707Z"
                    fill="white"
                  />
                  <path
                    d="M72.2801 32.6784L46.8635 25.0534C46.6246 24.9822 46.373 24.9822 46.134 25.0534L20.7174 32.6784C20.181 32.8385 19.8125 33.3341 19.8125 33.8959V61.8543C19.8125 73.0935 36.9484 83.0899 46.1239 85.9441C46.2459 85.9823 46.373 86 46.5 86C46.627 86 46.7541 85.9823 46.8761 85.9441C56.0516 83.0873 73.1875 73.0934 73.1875 61.8541V33.8959C73.1875 33.3341 72.819 32.841 72.2801 32.6784ZM70.6459 61.8541C70.6459 70.7779 56.5066 80.1618 46.5 83.3973C36.4935 80.1618 22.3541 70.7779 22.3541 61.8541V34.8414L46.5 27.5976L70.6459 34.8414V61.8541V61.8541Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="0.513153"
                    width="123.088"
                    height="143.605"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear4"
                    x1="69.75"
                    y1="2"
                    x2="10"
                    y2="125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF0E39" />
                    <stop offset="1" stopColor="#CE334F" />
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect
                      width="61"
                      height="61"
                      fill="white"
                      transform="translate(16 25)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials-section">
        <div className="container testimonials">
          <div className="testimonials-desc">
            <div className="testimony-subtitle">Testimonials</div>
            <h1>Trusted by thousand of vehicle owners all accross Zimbabwe</h1>
            <p>
              Since its launch Motortrade Affrica has received tons of love from
              vehicle owners from all over Zimbabwe looking to get
              vehicle/travel insurance conveniently and at reasonable price. We
              are the #1 vehicle insurance portal
            </p>
            <AccentButtonCTA to="/products" text="Get Insurance Now" />
          </div>
          <Slider {...settings}>
            <div className="testimony">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="testimony-person">
                <img alt="testimony-person" src="image7.png"></img>
                <span>Jane Doe</span>
              </div>
            </div>
            <div className="testimony">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="testimony-person">
                <img alt="testimony-person" src="image7.png"></img>
                <span>Jane Doe</span>
              </div>
            </div>
            <div className="testimony">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="testimony-person">
                <img alt="testimony-person" src="image7.png"></img>
                <span>Jane Doe</span>
              </div>
            </div>
            <div className="testimony">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="testimony-person">
                <img alt="testimony-person" src="image7.png"></img>
                <span>Jane Doe</span>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <section className="subscribe-section">
        <div className="container">
          <div className="subscribe-container">
            <h1>Subscribe to our newsletter</h1>
            <form>
              <div className="form-group">
                <input placeholder="Email Address"></input>
                <Button bg="accent.1">Subscribe</Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
