import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCompany } from "../redux/companySlice";
import axiosInstance from "../axios";
import { auth, google } from "./firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(0);
  const dispatch = useDispatch();

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function googlesign() {
    return auth.signInWithPopup(google);
  }

  function changeName(name) {
    const update = {
      displayName: name,
    };
    return auth.currentUser.updateProfile(update);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    setRole(0);
    dispatch(setCompany(undefined));
    return auth.signOut();
  }

  function reset(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function sendVerificationEmail(email) {
    return auth;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log(user);
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // console.log(user);
      // setCurrentUser(user);
      // setLoading(false);
      if (!user) {
        dispatch(setCompany(undefined));
        return;
      }
      axiosInstance
        .get("/user/" + user.uid)
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          if (res.error) {
            console.log(res.message);
          } else {
            setRole(res.data);
            if (res.data.role_company) {
              dispatch(setCompany(res.data.role_company));
            } else {
              dispatch(setCompany(undefined));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    reset,
    changeName,
    googlesign,
    sendVerificationEmail,
    role,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
