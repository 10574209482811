import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { Image } from "@chakra-ui/image";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import axiosInstance from "../../helpers/axios";
import { useHistory } from "react-router";

function ThirdPartyCompare({ step, setStep, data, setData }) {
  const [visa, setVisa] = useState(true);
  const [ecocash, setEcocash] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [quote, setQuote] = useState();
  const [loading, setLoading] = useState(false);

  const [rate, setRate] = useState(1);
  const [amount, setAmount] = useState();

  const [checkoutloading, setCheckoutloading] = useState(false);

  const toast = useToast();
  const history = useHistory();
  // console.log(data);
  const calculate = (qqoute) => {
    console.log(qqoute);
    let amount = 0;
    if (data.extra.fire) {
      let calc =
        (parseFloat(qqoute.pricing_fire_premium) / 100) *
        parseFloat(data.vehicle.value);

      amount =
        calc +
        parseFloat(qqoute.pricing_fire_rta) +
        parseFloat(qqoute.pricing_fire_stamp);
    } else {
      amount =
        parseFloat(qqoute.pricing_3rdparty_premium) +
        parseFloat(qqoute.pricing_3rdparty_rta) +
        parseFloat(qqoute.pricing_3rdparty_stamp);
    }
    console.log(amount);
    return amount;
  };

  useEffect(() => {
    if (data.vehicle) {
      setLoading(true);
      let request = {
        vehicle: data.vehicle,
        extra: data.extra,
        usd: visa,
        type: "3rdparty",
        quote: quote,
      };

      axiosInstance
        .post("/getquotes", request)
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          setLoading(false);

          setQuotes(res.data);
          if (res.data.length > 0) {
            setQuote(res.data[0]);
            setAmount(calculate(res.data[0]) * rate);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      console.log("Nothing");
    }
  }, []);

  const handleCheckout = () => {
    setCheckoutloading(true);
    let request = { vehicle: data.vehicle, extra: data.extra, quote: quote };
    axiosInstance
      .post("/checkout", request)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        // history.push("https://paynow.co.zw");
        window.location.href = "https://www.paynow.co.zw";
      });
  };

  return (
    <Container maxW="container.lg">
      <Grid
        gap={10}
        mb={5}
        templateColumns={{ base: "100%", md: "4fr 4fr", lg: "5fr 3fr" }}
      >
        <GridItem>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            p={3}
            bg={visa && "primary.2"}
            borderColor="#CCCCCC"
            cursor="pointer"
            onClick={() => {
              if (!checkoutloading) {
                setVisa(true);
                setEcocash(false);
                setRate(1);
              }
            }}
            mb={{ base: 2, md: 5 }}
          >
            <Flex align="center" justify="space-between">
              <Image
                src={
                  process.env.PUBLIC_URL + "/visa-logo-png-2026-1-1100x200.png"
                }
                maxWidth={20}
              />

              <Flex
                h={5}
                w={5}
                bg={visa && "accent.1"}
                borderWidth="1px"
                borderColor={!visa && "#CCCCCC"}
                color={"white"}
                align="center"
                justify="center"
                borderRadius="50%"
              >
                {visa && <CheckIcon w={2} h={2} />}
              </Flex>
            </Flex>
          </Box>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            p={3}
            bg={ecocash && "primary.2"}
            borderColor="#CCCCCC"
            cursor="pointer"
            onClick={() => {
              if (!checkoutloading) {
                setVisa(false);
                setEcocash(true);
                setRate(85);
              }
            }}
            mb={{ base: 2, md: 5 }}
          >
            <Flex align="center" justify="space-between">
              <Image
                src={process.env.PUBLIC_URL + "/208274.svg"}
                maxWidth={20}
              />

              <Flex
                h={5}
                w={5}
                bg={ecocash && "accent.1"}
                borderWidth="1px"
                borderColor={!ecocash && "#CCCCCC"}
                color={"white"}
                align="center"
                justify="center"
                borderRadius="50%"
              >
                {ecocash && <CheckIcon w={2} h={2} />}
              </Flex>
            </Flex>
          </Box>
        </GridItem>
        <GridItem>
          <Box borderRadius="lg" borderColor="primary.1" boxShadow="2xl">
            <Box
              bg={"primary.1"}
              borderTopLeftRadius="lg"
              borderTopRightRadius="lg"
              py={5}
            >
              <Flex align="center" p={3} color="white">
                <Flex
                  h={5}
                  w={5}
                  bg={data.vehicle && "green.300"}
                  borderWidth="1px"
                  borderColor={!data.vehicle && "#CCCCCC"}
                  color={"white"}
                  align="center"
                  justify="center"
                  borderRadius="50%"
                  mr={3}
                >
                  {data.vehicle && <CheckIcon w={2} h={2} />}
                </Flex>
                <Text flex="1">Insurance</Text>
                <Flex>
                  <Text>
                    ${!visa && amount && "ZWL "}
                    {amount && (amount * rate).toFixed(2)}
                  </Text>
                </Flex>
              </Flex>
              {/* <Flex align="center" p={3} color="white">
                <Flex
                  h={5}
                  w={5}
                  bg={data.extra.license && "green.300"}
                  borderWidth="1px"
                  borderColor={!data.extra.license && "#CCCCCC"}
                  color={"white"}
                  align="center"
                  justify="center"
                  borderRadius="50%"
                  mr={3}
                >
                  {data.extra.license && <CheckIcon w={2} h={2} />}
                </Flex>
                <Text flex="1">License</Text>
                <Flex>
                  <Text>
                    ${!visa && amount && "ZWL "}
                    {amount && amount * rate}
                  </Text>
                </Flex>
              </Flex> */}
              {/* <Flex align="center" p={3} color="white">
                <Flex
                  h={5}
                  w={5}
                  bg={data.extra.radio && "green.300"}
                  borderWidth="1px"
                  borderColor={!data.extra.radio && "#CCCCCC"}
                  color={"white"}
                  align="center"
                  justify="center"
                  borderRadius="50%"
                  mr={3}
                >
                  {data.extra.radio && <CheckIcon w={2} h={2} />}
                </Flex>
                <Text flex="1">Radio License</Text>
                <Flex>
                  <Text>
                    ${!visa && amount && "ZWL "}
                    {amount && amount * rate}
                  </Text>
                </Flex>
              </Flex> */}
            </Box>

            <Box p={3}>
              <Heading size="sm" mb={3}>
                <Text>Select Insurer</Text>
              </Heading>

              <Skeleton
                h={loading && 20}
                mb={3}
                speed={1.2}
                isLoaded={!loading}
              ></Skeleton>
              <Skeleton
                h={loading && 20}
                mb={3}
                speed={1}
                isLoaded={!loading}
              ></Skeleton>
              <Skeleton
                h={loading && 20}
                mb={3}
                speed={1.3}
                isLoaded={!loading}
              ></Skeleton>

              <Stack>
                {quotes.map((qquote, index) => (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    cursor={"pointer"}
                    key={qquote.pricing_id}
                    onClick={() => {
                      if (!checkoutloading) {
                        setQuote(qquote);
                        setAmount((calculate(qquote) * rate).toFixed(2));
                      }
                    }}
                  >
                    <Flex p={3} w={"100%"}>
                      <Flex
                        h={5}
                        w={5}
                        bg={qquote === quote && "green.300"}
                        borderWidth="1px"
                        // borderColor={!data.vehicle && "#CCCCCC"}
                        color={"white"}
                        align="center"
                        justify="center"
                        borderRadius="50%"
                        mr={3}
                      >
                        {qquote === quote && <CheckIcon w={2} h={2} />}
                      </Flex>
                      <Stack flex="1">
                        <Text
                          w={"100%"}
                          style={{ float: "right", fontWeight: "bold" }}
                        >
                          ${!visa && amount && "ZWL "}
                          {(calculate(qquote) * rate).toFixed(2)}
                        </Text>

                        <Image
                          src={qquote.company.company_logo}
                          maxWidth={100}
                        />
                      </Stack>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
          {quote && (
            <Button
              my={5}
              colorScheme="red"
              onClick={handleCheckout}
              isLoading={checkoutloading}
            >
              Checkout Now
            </Button>
          )}
        </GridItem>
      </Grid>
      <Flex justify="space-between" align="center">
        <Button
          type={"button"}
          disabled={step === 1 || checkoutloading}
          onClick={() => {
            setStep(step > 1 ? step - 1 : 1);
          }}
        >
          Previous
        </Button>
        <Button type={"submit"} disabled={step === 4}>
          Next
        </Button>
      </Flex>
    </Container>
  );
}

export default ThirdPartyCompare;
