import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Heading, Stack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useState } from "react";
import axiosInstance from "../../helpers/axios";

function ModelSingleUse({ model, use }) {
  const [vehicleuse, setUse] = useState(use);
  const [title, setTitle] = useState(vehicleuse ? vehicleuse.use_title : "");
  const [loading, setLoading] = useState(false);

  console.log(use);
  const toast = useToast();

  const handleSubmit = () => {
    setLoading(true);
    if (!title) {
      toast({
        description: "Vehicle Type is required",
        status: "error",
        position: "top-right",
      });
      setLoading(false);
      return;
    }
    axiosInstance
      .post("/edituse/" + use.use_id, { title: title })
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          setTitle("");
          toast({
            description: res.message,
            status: "success",
            position: "top-right",
          });
          setUse(res.data);
          //setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Stack pt={3}>
      <InputGroup size="md">
        <Input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          disabled={loading}
          pr="4.5rem"
          type="text"
          placeholder="Vehicle Use Title"
        />
        <InputRightElement width="9rem">
          {vehicleuse && (
            <>
              <Button
                h="1.75rem"
                size="sm"
                mr={1}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Edit
              </Button>
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleSubmit}
                isLoading={loading}
              >
                Delete
              </Button>
            </>
          )}
        </InputRightElement>
      </InputGroup>
    </Stack>
  );
}

export default ModelSingleUse;
