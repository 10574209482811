import { IconButton } from "@chakra-ui/button";
import { EditIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axios";
import AddModelFragment from "./AddModelFragment";
import ModelFragment from "./ModelFragment";

function ModelsFragment() {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/models")
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          console.log(res.message);
        } else {
          setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <Stack>
      <Heading>
        <Text>Pricing Models</Text>
        <Box mt={2} h={"2px"} w={"60%"} maxW="100px" bg={"accent.1"} />
      </Heading>
      {loading && (
        <Stack>
          <Skeleton w="60%" h={"10"} speed={0.5} />
          <Skeleton w="60%" h={"10"} speed={0.5} />
          <Skeleton w="60%" h={"10"} speed={0.4} />
          <Skeleton w="60%" h={"10"} speed={0.6} />
        </Stack>
      )}
      {models.map((model, index) => (
        <Box
          key={index}
          p={2}
          _hover={{ bg: "primary.2" }}
          m={0}
          style={{
            borderBottom: "1px solid #ccc",
            marginBottom: "0px",
          }}
        >
          <ModelFragment model={model} />
        </Box>
      ))}
      <AddModelFragment setModels={setModels} />
    </Stack>
  );
}

export default ModelsFragment;
