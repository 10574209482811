//Providers
//
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../google/AuthContext";
import { store } from "../redux/store";

export default function AdminRoute({ component: Component, ...rest }) {
  const { role } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return role && role.role_admin === 1 ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/`} />
        );
      }}
    ></Route>
  );
}
