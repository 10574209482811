import { Box, Heading, Stack, Text } from "@chakra-ui/layout";
import React from "react";

function DashboardFragment({ slug }) {
  return (
    <Stack>
      <Heading>
        <Text>Dashboard</Text>
        <Box mt={2} h={"2px"} w={"60%"} maxW="100px" bg={"accent.1"} />
      </Heading>
    </Stack>
  );
}

export default DashboardFragment;
