import React, { useState } from "react";
import AccentButtonCTA from "../utils/AccentButtonCTA";
import ModalVideo from "react-modal-video";

function HeroSection() {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="hero-section">
      <div className="hero-content container">
        <div className="hero-main-title">Affordable</div>
        <div className="hero-sub-title">
          <span>insurance</span>
          <br />
          products
        </div>
        <AccentButtonCTA to="/products" text="Get insurance" />
        <div className="watch-video" onClick={() => setOpen(true)}>
          <span>Watch Tutorial</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="SwC6tV-2rg8"
          onClose={() => setOpen(false)}
        />
      </div>
    </section>
  );
}

export default HeroSection;
