import { Box, Flex } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AdminRoute from "../../helpers/basic/AdminRoute";
import EditModelFragment from "./EditModelFragment";
import ModelsFragment from "./ModelsFragment";
import Sidebar from "./Sidebar";

function AdminDashboard() {
  const [loading, setLoading] = useState(false);
  let { path, variable } = useParams();
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Flex direction={{ base: "column", lg: "row" }}>
      <Box p={3} w="100%" maxW={{ base: "100%", lg: "330px" }} flex="1">
        <Sidebar loading={loading} />
      </Box>
      <Box p={3} flex="1">
        {path === undefined && !loading && (
          //   <AdminRoute component={DashboardFragment} />
          <>Admin</>
        )}
        {path === "models" && !loading && (
          <AdminRoute component={ModelsFragment} />
        )}
        {path === "vehicletype" && !loading && (
          <AdminRoute component={EditModelFragment} id={variable} />
        )}
      </Box>
    </Flex>
  );
}

export default AdminDashboard;
