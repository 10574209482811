import { extendTheme } from "@chakra-ui/react";
const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "white",
        color: "primary.1",
      },
    },
  },
  fonts: {
    body: "Poppins",
  },
  colors: {
    primary: {
      1: "#004156",
      2: "#F1FCFF",
    },
    accent: {
      1: "#EE2B43",
      2: "#AE061B",
    },
  },
});
export default theme;
