import { createSlice } from "@reduxjs/toolkit";
export const companySlice = createSlice({
  name: "company",
  initialState: {
    value: sessionStorage.getItem("company")
      ? JSON.parse(sessionStorage.getItem("company"))
      : undefined,
  },
  reducers: {
    setCompany: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload !== undefined) {
        state.value = action.payload;
        sessionStorage.setItem("company", JSON.stringify(action.payload));
      } else {
        state.value = undefined;
        sessionStorage.setItem("company", undefined);
      }

      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
