import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import {  Heading, Text } from "@chakra-ui/layout";
import React, { useState } from "react";
import ThirdPartyCompare from "../components/steps/ThirdPartyCompare";
import ThirdPartyExtras from "../components/steps/ThirdPartyExtras";
import ThirdPartyStep1 from "../components/steps/ThirdPartyStep1";
import ThirdPartyStep2 from "../components/steps/ThirdPartyStep2";
import ThirdPartySteps from "../components/utils/ThirdPartySteps";

function ThirdParty() {
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  return (
    <div className="container" pt={100}>
      <Heading>
        <Text>Get Insurance</Text>
      </Heading>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Get Insurance</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#" color={"red.500"}>
            3rd Party
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <ThirdPartySteps step={step} />
      {step === 1 && (
        <ThirdPartyStep1
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
        />
      )}
      {step === 2 && (
        <ThirdPartyStep2
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
        />
      )}
      {step === 3 && (
        <ThirdPartyExtras
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
        />
      )}
      {step === 4 && (
        <ThirdPartyCompare
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
        />
      )}
    </div>
  );
}

export default ThirdParty;
