import React, { useState } from "react";
import { Box, Container, Flex, Text } from "@chakra-ui/layout";
import { CheckIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/button";
function ThirdPartyExtras({ step, setStep, data, setData }) {
  const [radio, setRadio] = useState(data?.extra?.radio);
  const [license, setLicense] = useState(data?.extra?.license);
  const [fire, setFire] = useState(data?.extra?.fire);

  console.log(data);
  return (
    <Container maxW="container.lg">
      {/* <Box
        borderWidth="1px"
        borderRadius="lg"
        p={3}
        bg={radio && "primary.2"}
        borderColor="#CCCCCC"
        cursor="pointer"
        onClick={() => {
          setRadio(!radio);
        }}
        mb={10}
      >
        <Flex align="center" justify="space-between">
          <Text>Include Radio License</Text>

          <Flex
            h={5}
            w={5}
            bg={radio && "accent.1"}
            borderWidth="1px"
            borderColor={!radio && "#CCCCCC"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            {radio && <CheckIcon w={2} h={2} />}
          </Flex>
        </Flex>
      </Box>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={3}
        bg={license && "primary.2"}
        borderColor="#CCCCCC"
        cursor="pointer"
        onClick={() => {
          setLicense(!license);
        }}
        mb={10}
      >
        <Flex align="center" justify="space-between">
          <Text>Include Vehicle License</Text>

          <Flex
            h={5}
            w={5}
            bg={license && "accent.1"}
            borderWidth="1px"
            borderColor={!license && "#CCCCCC"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            {license && <CheckIcon w={2} h={2} />}
          </Flex>
        </Flex>
      </Box>
       */}
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={3}
        bg={fire && "primary.2"}
        borderColor="#CCCCCC"
        cursor="pointer"
        onClick={() => {
          setFire(!fire);
        }}
        mb={10}
      >
        <Flex align="center" justify="space-between">
          <Text>Include Fire & Theft Insurance</Text>

          <Flex
            h={5}
            w={5}
            bg={fire && "accent.1"}
            borderWidth="1px"
            borderColor={!fire && "#CCCCCC"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            {fire && <CheckIcon w={2} h={2} />}
          </Flex>
        </Flex>
      </Box>

      <Flex justify="space-between" align="center">
        <Button
          type={"button"}
          disabled={step === 1}
          onClick={() => {
            setStep(step > 1 ? step - 1 : 1);
          }}
        >
          Previous
        </Button>
        <Button
          type={"submit"}
          onClick={() => {
            let newData = data;
            let extra = {
              radio: radio ? true : false,
              license: license ? true : false,
              fire: fire ? true : false,
            };
            newData.extra = extra;
            setData(newData);
            setStep(step + 1);
          }}
        >
          Next
        </Button>
      </Flex>
    </Container>
  );
}

export default ThirdPartyExtras;
