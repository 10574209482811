import { Image } from "@chakra-ui/image";
import { Box, Center, Flex, Grid, GridItem } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyRoute from "../../helpers/basic/CompanyRoute";
import DashboardFragment from "./DashboardFragment";
import PricingFragment from "./PricingFragment";
import SettingsFragment from "./SettingsFragment";
import Sidebar from "./Sidebar";

function Dashboard() {
  let { slug, path, variable } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Flex direction={{ base: "column", lg: "row" }}>
      <Box p={3} w="100%" maxW={{ base: "100%", lg: "330px" }} flex="1">
        <Sidebar loading={loading} company={company} slug={slug} />
      </Box>
      <Box p={3} flex="1">
        {path === undefined && !loading && (
          <CompanyRoute component={DashboardFragment} slug={slug} />
        )}
        {path === "settings" && !loading && (
          <CompanyRoute
            component={SettingsFragment}
            title={"Settings"}
            slug={slug}
          />
        )}
        {path === "pricing" && !loading && (
          <CompanyRoute
            component={PricingFragment}
            title={"Pricing"}
            slug={slug}
          />
        )}
      </Box>
    </Flex>
  );
}

export default Dashboard;
