import { Avatar } from "@chakra-ui/avatar";
import { IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Flex, List, ListItem, Spacer } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { Tag, TagLabel } from "@chakra-ui/tag";
import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/google/AuthContext";
import BorderButton from "../utils/BorderButton";

function Header() {
  const { currentUser, logout, role } = useAuth();
  const [company, setCompany] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <header>
        <div style={{ padding: "0 20px" }}>
          <Flex
            justify="space-between"
            align="center"
            className="nav-container"
            py={6}
          >
            <Link to="/" className="site-logo">
              <img src={process.env.PUBLIC_URL + "/logo.png"} alt="" />
            </Link>

            <Flex align="center">
              <Box display={{ base: "none", md: "block" }}>
                <nav>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    {/* <li>
                      <Link to="#">Insurers</Link>
                    </li> */}
                    <li>
                      <Link to="#">Contact</Link>
                    </li>
                    {!currentUser && (
                      <>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                        <li>
                          <BorderButton
                            to="/signup"
                            text="Signup"
                            className="primary-btn"
                          ></BorderButton>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </Box>
              {currentUser && (
                <>
                  <Menu>
                    <MenuButton>
                      <Tag
                        size="sm"
                        colorScheme="blue"
                        borderRadius="full"
                        mr={{ base: 3, md: "none" }}
                      >
                        <Avatar
                          size="sm"
                          ml={-2}
                          mr={{
                            base: -2,
                            md: currentUser.displayName ? 2 : -2,
                          }}
                          colorScheme="red"
                          name={currentUser.displayName}
                          src={
                            !currentUser.photoURL ? "" : currentUser.photoURL
                          }
                        />
                        <TagLabel display={{ base: "none", md: "block" }}>
                          {currentUser.displayName}
                        </TagLabel>
                      </Tag>
                    </MenuButton>
                    <MenuList zIndex="3">
                      <MenuGroup title="Profile">
                        <MenuItem>My Account</MenuItem>
                        <MenuItem>Payments </MenuItem>
                        <MenuItem>
                          <Link to="#" onClick={logout}>
                            Logout
                          </Link>
                        </MenuItem>
                      </MenuGroup>
                      {role !== 0 && role.role_company !== null && (
                        <>
                          <MenuDivider />
                          <MenuGroup title={role.role_company.company_name}>
                            <MenuItem>
                              <Link
                                to={
                                  "/dashboard/" + role.role_company.company_slug
                                }
                              >
                                Dashboard
                              </Link>
                            </MenuItem>
                          </MenuGroup>
                        </>
                      )}
                      {role !== 0 && role.role_admin && (
                        <>
                          <MenuDivider />
                          <MenuGroup title="Admin Center">
                            <MenuItem>
                              <Link to={"/admin/"}>Admin Dashboard</Link>
                            </MenuItem>
                          </MenuGroup>
                        </>
                      )}
                      <MenuDivider />
                      <MenuGroup title="Help">
                        <MenuItem>Docs</MenuItem>
                        <MenuItem>FAQ</MenuItem>
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                </>
              )}
              <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
                display={{ base: "flex", md: "none" }}
              >
                Menu
              </IconButton>
            </Flex>
          </Flex>
        </div>
      </header>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />

          <Box h={20} />

          <Link to="/" style={{ textDecoration: "none" }} onClick={onClose}>
            <Flex
              align="center"
              p="4"
              w="100%"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              color="primary.1"
              _hover={{
                bg: "primary.2",
                color: "primary.1",
              }}
            >
              Home
            </Flex>
          </Link>
          {/* <Link to="#" style={{ textDecoration: "none" }} onClick={onClose}>
            <Flex
              align="center"
              p="4"
              w="100%"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              color="primary.1"
              _hover={{
                bg: "primary.2",
                color: "primary.1",
              }}
            >
              Insurers
            </Flex>
          </Link> */}
          <Link to="#" style={{ textDecoration: "none" }} onClick={onClose}>
            <Flex
              align="center"
              p="4"
              w="100%"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              color="primary.1"
              _hover={{
                bg: "primary.2",
                color: "primary.1",
              }}
            >
              Contact
            </Flex>
          </Link>
          {role !== 0 && role?.role_company !== null && (
            <Link
              to={"/dashboard/" + role.role_company.company_slug}
              style={{ textDecoration: "none" }}
              onClick={onClose}
            >
              <Flex
                align="center"
                p="4"
                w="100%"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="primary.1"
                _hover={{
                  bg: "primary.2",
                  color: "primary.1",
                }}
              >
                {role.role_company.company_name} Dashboard
              </Flex>
            </Link>
          )}
          {role !== 0 && role.role_admin && (
            <Link
              to={"/admin/"}
              style={{ textDecoration: "none" }}
              onClick={onClose}
            >
              <Flex
                align="center"
                p="4"
                w="100%"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="primary.1"
                _hover={{
                  bg: "primary.2",
                  color: "primary.1",
                }}
              >
                Admin Dashboard
              </Flex>
            </Link>
          )}
          {!currentUser ? (
            <>
              <Link
                to="/login"
                style={{ textDecoration: "none" }}
                onClick={onClose}
              >
                <Flex
                  align="center"
                  p="4"
                  w="100%"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  color="primary.1"
                  _hover={{
                    bg: "primary.2",
                    color: "primary.1",
                  }}
                >
                  Login
                </Flex>
              </Link>
              <Link
                to="/signup"
                style={{ textDecoration: "none" }}
                onClick={onClose}
              >
                <Flex
                  align="center"
                  p="4"
                  w="100%"
                  role="group"
                  cursor="pointer"
                  color="white"
                  bg={"accent.1"}
                  _hover={{
                    bg: "accent.2",
                  }}
                >
                  Signup
                </Flex>
              </Link>
            </>
          ) : (
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={() => {
                logout();
                onClose();
              }}
            >
              <Flex
                align="center"
                p="4"
                w="100%"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="primary.1"
                _hover={{
                  bg: "primary.2",
                  color: "primary.1",
                }}
              >
                Logout
              </Flex>
            </Link>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Header;
