import { createSlice } from "@reduxjs/toolkit";
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: sessionStorage.getItem("cart")
      ? JSON.parse(sessionStorage.getItem("cart"))
      : [],
  },
  reducers: {
    addToCart: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      let found = -1;

      state.value.map((item, index) => {
        if (
          item.variation.variation_id === action.payload.variation.variation_id
        ) {
          // alert("Vehicle Already exist");
          // const toast = useToast();
          found = index;
        }
        return state;
      });

      if (found < 0) {
        let newlist = [...state.value, action.payload];
        sessionStorage.setItem("cart", JSON.stringify(newlist));
        state.value = newlist;
      } else {
        let newlist = [...state.value];
        console.log(found);
        newlist[found].count += action.payload.count;
        sessionStorage.setItem("cart", JSON.stringify(newlist));
        state.value = newlist;
      }

      return state;
    },
    reduceCart(state, action) {
      let newcart = [...state.value];
      for (let index = 0; index < newcart.length; index++) {
        const element = newcart[index];

        if (
          action.payload.variation.variation_id ===
          element.variation.variation_id
        ) {
          newcart[index].count = newcart[index].count - 1;
          if (newcart[index].count < 1) {
            newcart.splice(index, 1);
          }
        }
      }

      sessionStorage.setItem("cart", JSON.stringify(newcart));
      state.value = newcart;

      return state;
    },
    clearCart(state) {
      sessionStorage.setItem("cart", JSON.stringify([]));
      state.value = [];
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, reduceCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;