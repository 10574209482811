import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Heading, Stack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useState } from "react";
import axiosInstance from "../../helpers/axios";

function EditModelTitleFragment({ setModels, id, model }) {
  const [loading, setLoading] = useState();
  const [title, setTitle] = useState(model ? model.type_name : "");

  console.log(title);
  const toast = useToast();

  function handleSubmit() {
    setLoading(true);
    if (!title) {
      toast({
        description: "Vehicle Type is required",
        status: "error",
        position: "top-right",
      });
      setLoading(false);
      return;
    }
    axiosInstance
      .post("/editmodel/" + id, { title: title })
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          setTitle("");
          toast({
            description: res.message,
            status: "success",
            position: "top-right",
          });
          setModels(res.data);
          //setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  return (
    <Stack pt={10}>
      <Heading size={"md"}>
        <Text>Edit Vehicle Type</Text>
      </Heading>
      <InputGroup size="md">
        <Input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          disabled={loading}
          pr="4.5rem"
          type="text"
          placeholder="Type Title"
        />
        <InputRightElement width="9.5rem">
          {model && (
            <>
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleSubmit}
                isLoading={loading}
                mr={1}
              >
                Edit
              </Button>
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleSubmit}
                isLoading={loading}
              >
                Delete
              </Button>
            </>
          )}
        </InputRightElement>
      </InputGroup>
    </Stack>
  );
}

export default EditModelTitleFragment;
