import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import companySlice from "./companySlice";
import roleSlice from "./roleSlice";

export default configureStore({
  reducer: {
    cart: cartSlice,
    company: companySlice,
    role: roleSlice,
  },
});
