import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Container, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Collapse } from "@chakra-ui/transition";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/google/AuthContext";

function ResetPassword() {
  const { isOpen, onToggle } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const { reset } = useAuth();
  const toast = useToast();
  const resetAccount = async () => {
    setSent(false);
    setLoading(true);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      toast({
        description: "Invalid Email Address",
        status: "error",
        position: "top-right",
      });
      setLoading(false);
      return;
    }
    try {
      await reset(email);
      setSent(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <Container style={{ marginBottom: "100px" }}>
      {sent && (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Reset Email Sent!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            If you have an account with us, an email has been sent. If you cant
            find it, please check your spam
          </AlertDescription>
        </Alert>
      )}
      <Collapse in={isOpen && !sent} animateOpacity>
        <FormControl id="email-reset">
          <FormLabel>Email address</FormLabel>
          <InputGroup size="md">
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              pr="4.5rem"
              type="email"
              placeholder="Enter email"
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="md"
                onClick={resetAccount}
                isLoading={loading}
                mr={2}
              >
                Reset
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormHelperText>Enter email to reset password.</FormHelperText>
        </FormControl>
      </Collapse>

      {!loading && (
        <Text pt={10}>
          {isOpen
            ? "Remembered your password?"
            : "Forgot your account password?"}{" "}
          <Link to="#" style={{ fontWeight: "bold" }} onClick={onToggle}>
            {isOpen ? "Login" : "Reset"}
          </Link>
        </Text>
      )}
    </Container>
  );
}

export default ResetPassword;
