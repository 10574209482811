import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Container, Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useFormik } from "formik";
import React, { useState } from "react";

function ThirdPartyStep2({ step, setStep, data, setData }) {
  const [name, setName] = useState(data?.owner?.name);
  const [surname, setSurname] = useState(data?.owner?.surname);
  const [dob, setDob] = useState(data?.owner?.dob);
  const [idno, setIdno] = useState(data?.owner?.idno);
  const [citizenship, setCitizenship] = useState(data?.owner?.citizenship);
  const [email, setEmail] = useState(data?.owner?.email);
  const [address, setAddress] = useState(data?.owner?.address);
  const [city, setCity] = useState(data?.owner?.city);
  const [kinName, setKinName] = useState(data?.owner?.kinName);
  const [kinPhone, setKinPhone] = useState(data?.owner?.kinPhone);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.surname) {
      errors.surname = "Last Name is required";
    }
    if (!values.dob) {
      errors.dob = "Date of Birth is required";
    }
    if (!values.idno) {
      errors.idno = "ID No. or Passpport No. is required";
    }
    if (!values.citizenship) {
      errors.citizenship = "Citizenship is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }
    if (!values.city) {
      errors.city = "City is required";
    }
    if (!values.kinName) {
      errors.kinName = "Next of Kin is required";
    }
    if (!values.kinPhone) {
      errors.kinPhone = "Next of Kin contact details is required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: name,
      surname: surname,
      dob: dob,
      idno: idno,
      citizenship: citizenship,
      email: email,
      address: address,
      city: city,
      kinName: kinName,
      kinPhone: kinPhone,
    },
    validate,
    onSubmit: (values, actions) => {
      let carryOver = data;
      carryOver.owner = values;
      console.log(carryOver);
      setData(carryOver);
      setStep(step + 1);
      //submit(values, actions);
    },
  });

  return (
    <form style={{ marginTop: "50px" }} onSubmit={formik.handleSubmit}>
      <Container maxW="container.lg">
        <Grid
          gap={10}
          mb={10}
          templateColumns={{ base: "100%", md: "repeat(2, 1fr)" }}
        >
          <GridItem>
            <FormControl id="name" isInvalid={formik.errors.name}>
              <FormLabel>First Name</FormLabel>
              <Input
                placeholder="First Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  formik.values.name = e.target.value;
                  formik.errors.name = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="surname" isInvalid={formik.errors.surname}>
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="First Name"
                value={surname}
                onChange={(e) => {
                  setSurname(e.target.value);
                  formik.values.surname = e.target.value;
                  formik.errors.surname = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.surname}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="dob" isInvalid={formik.errors.dob}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                placeholder="Date of Birth"
                value={dob}
                type={"date"}
                onChange={(e) => {
                  setDob(e.target.value);
                  formik.values.dob = e.target.value;
                  formik.errors.dob = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.dob}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="idno" isInvalid={formik.errors.idno}>
              <FormLabel>ID No. or Passport</FormLabel>
              <Input
                placeholder="ID No. or Passport No."
                value={idno}
                onChange={(e) => {
                  setIdno(e.target.value);
                  formik.values.idno = e.target.value;
                  formik.errors.idno = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.idno}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="citizenship" isInvalid={formik.errors.citizenship}>
              <FormLabel>Citizenship</FormLabel>
              <Input
                placeholder="Citizenship"
                value={citizenship}
                onChange={(e) => {
                  setCitizenship(e.target.value);
                  formik.values.citizenship = e.target.value;
                  formik.errors.citizenship = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.citizenship}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="email" isInvalid={formik.errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email"
                value={email}
                type={"email"}
                onChange={(e) => {
                  setEmail(e.target.value);
                  formik.values.email = e.target.value;
                  formik.errors.email = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="address" isInvalid={formik.errors.address}>
              <FormLabel>Address</FormLabel>
              <Input
                placeholder="Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  formik.values.address = e.target.value;
                  formik.errors.address = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="city" isInvalid={formik.errors.city}>
              <FormLabel>City</FormLabel>
              <Input
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  formik.values.city = e.target.value;
                  formik.errors.city = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="kin" isInvalid={formik.errors.kinName}>
              <FormLabel>Next of Kin</FormLabel>
              <Input
                placeholder="Next of Kin"
                value={kinName}
                onChange={(e) => {
                  setKinName(e.target.value);
                  formik.values.kinName = e.target.value;
                  formik.errors.kinName = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.kinName}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="kin" isInvalid={formik.errors.kinPhone}>
              <FormLabel>Next of Kin Contact Details</FormLabel>
              <Input
                placeholder="Next of Kin Phone"
                value={kinPhone}
                onChange={(e) => {
                  setKinPhone(e.target.value);
                  formik.values.kinPhone = e.target.value;
                  formik.errors.kinPhone = null;
                }}
              ></Input>
              <FormErrorMessage>{formik.errors.kinPhone}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex justify="space-between" align="center">
          <Button
            type={"button"}
            disabled={step === 1}
            onClick={() => {
              setStep(step > 1 ? step - 1 : 1);
            }}
          >
            Previous
          </Button>
          <Button
            type={"submit"}
            onClick={() => {
              //setStep(step + 1);
            }}
          >
            Next
          </Button>
        </Flex>
      </Container>
    </form>
  );
}

export default ThirdPartyStep2;
