import {
  Badge,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Grid,
  GridItem,
  Circle,
} from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { useToast } from "@chakra-ui/toast";
import React, { useState, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/button";
import {
  BellIcon,
  CheckIcon,
  ChevronDownIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axios";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";

import SinglePricing from "./SinglePricing";

function PricingFragment({ slug, title }) {
  const [loading, setLoading] = useState(false);
  const [pricings, setPricings] = useState([]);
  const [pricing, setPricing] = useState();
  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/pricing/" + slug)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          setPricings(res.data);
          if (res.data.length > 0) {
            setPricing(res.data[0]);
          }
          //setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const reload = () => {
    setLoading(true);
    setPricing(null);
    setPricings([]);
    axiosInstance
      .get("/pricing/" + slug)
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        if (res.error) {
          toast({
            description: res.message,
            status: "error",
            position: "top-right",
          });
        } else {
          setPricings(res.data);
          if (res.data.length > 0) {
            setPricing(res.data[0]);
          }
          //setModels(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Stack>
      <Heading>
        <Text>Pricing</Text>
        <Box mt={2} h={"2px"} w={"60%"} maxW="100px" bg={"accent.1"} />
      </Heading>
      <Stack>
        <Box bg={"primary.1"} p={1} my={5} borderRadius="8">
          <Flex w={"100%"} align="center">
            <Text flex="1" pl={3} fontWeight="500" color="white">
              {loading && "Loading..."}
              {pricing && !loading && pricing.type_name}
            </Text>
            <Menu>
              <MenuButton
                bg={"white"}
                as={Button}
                fontWeight="400"
                size="md"
                disabled={loading}
                rightIcon={!loading && <ChevronDownIcon />}
              >
                {loading && <Spinner />}
                {!loading && "Change Type"}
              </MenuButton>
              <MenuList>
                {pricings.map((pricing, index) => (
                  <MenuItem onClick={() => setPricing(pricing)}>
                    {pricing.type_name}
                  </MenuItem>
                ))}
                <MenuItem onClick={reload}>Reload</MenuItem>
              </MenuList>
            </Menu>
            {/* select>
            <optgroup label="Light Motor Vehicle">
              <option>Social</option>
            </optgroup>
            <optgroup label="Heavy Motor Vehicle">
              <option>Business</option>
            </optgroup><
          </select> */}
          </Flex>
        </Box>
        <Stack py={4} spacing="35px">
          {pricing &&
            pricing.uses.map((use, index) => (
              <Box
                p={3}
                mb={0}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                key={use.use_id}
              >
                <Heading size="md">
                  <Flex justify="space-between" align="center">
                    <Text color={use.pricing ? "green" : "red"}>
                      {use.use_title}
                    </Text>
                    <Circle
                      size="40px"
                      bg={use.pricing ? "green" : "red"}
                      color="white"
                    >
                      {use.pricing ? (
                        <CheckIcon size="sm" />
                      ) : (
                        <WarningTwoIcon size="sm" />
                      )}
                    </Circle>
                  </Flex>
                </Heading>
                <SinglePricing
                  setPricings={setPricings}
                  setPricing={setPricing}
                  use={use}
                />
              </Box>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PricingFragment;
