import { Box, Center, Heading, HStack, Text } from "@chakra-ui/layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { useFormik } from "formik";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useAuth } from "../helpers/google/AuthContext";
import { useToast } from "@chakra-ui/toast";
import { Container, Flex } from "@chakra-ui/layout";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ResetPassword from "../components/basic/ResetPassword";

function Login() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { login, googlesign } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const [redirect, setRedirect] = useState("/");
  const { search } = useLocation();

  useEffect(() => {
    if (search.includes("?redirect=")) {
      setRedirect(search.replace("?redirect=", ""));
    }
  }, [search]);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Password Required";
    }

    return errors;
  };

  const submit = async (values, actions) => {
    try {
      setLoading(true);
      await login(values.email, values.password);
      setLoading(false);
      history.push(redirect);
    } catch (error) {
      setLoading(false);
      toast({
        description: "Failed to login. " + error.message,
        status: "error",
        position: "top-right",
      });
    }
  };

  const loginWithGoogle = async () => {
    try {
      setLoading(true);
      await googlesign();
      setLoading(false);
      history.push(redirect);
    } catch (error) {
      setLoading(false);
      toast({
        description: "Failed to login. " + error.message,
        status: "error",
        position: "top-right",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values, actions) => {
      submit(values, actions);
    },
  });

  const handleTogglePassword = () => {
    setShow(!show);
  };

  return (
    <div className="container" pt={100}>
      <Heading>
        <Text>Login</Text>
      </Heading>
      <Breadcrumb mb={50}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#" color={"red.500"}>
            Login
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form style={{ marginTop: "50px" }}>
        <Container>
          <FormControl
            id="email"
            mb={10}
            isInvalid={formik.errors.email && formik.touched.email}
          >
            <FormLabel>Email Address</FormLabel>
            <Input
              bg={"#F7F7F7"}
              placeholder="Email Address"
              type="email"
              onChange={(e) => {
                formik.values.email = e.target?.value;
              }}
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl
            mb={10}
            id="password"
            isInvalid={formik.errors.password && formik.touched.password}
          >
            <FormLabel>Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                bg={"#F7F7F7"}
                placeholder="Enter password"
                onChange={(e) => {
                  formik.values.password = e.target?.value;
                }}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleTogglePassword}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>
          <Flex mt={5} mb={10} align={"center"} justify="space-between">
            <Button
              disabled={loading}
              isLoading={loading}
              colorScheme="red"
              onClick={formik.handleSubmit}
            >
              Login
            </Button>
            <Text>
              Don't have an account?{" "}
              <Link to="/signup" style={{ fontWeight: "bold" }}>
                Signup
              </Link>
            </Text>
          </Flex>
        </Container>
      </form>
      <ResetPassword />
      <Container>
        <Flex align="center" pt={10} mb={10}>
          <Box bg="primary.1" w="100%" p={0.2} color="white"></Box>
          <Text px={3}>or</Text>
          <Box bg="primary.1" w="100%" p={0.2} color="white"></Box>
        </Flex>
      </Container>

      <Container>
        <Center>
          <HStack>
            <Button
              isLoading={loading}
              colorScheme="facebook"
              leftIcon={<FaFacebook />}
            >
              Login with Facebook
            </Button>
            <Button
              isLoading={loading}
              colorScheme="red"
              onClick={loginWithGoogle}
              leftIcon={<FaGoogle />}
            >
              Login with Google
            </Button>
          </HStack>
        </Center>
      </Container>
    </div>
  );
}

export default Login;
