import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE;
let headers = {};
if (sessionStorage.token) {
  headers.Authorization = `Bearer ${sessionStorage.token}`;
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

export default axiosInstance;