import Dashboard from "../../layouts/insurer/Dashboard";
import AdminDashboard from "../../layouts/admin/Dashboard";
import ThirdParty from "../../layouts/ThirdParty";
import ProtectedRoute from "./ProtectedRoute";

export const ApplicationRoutes = () => {
  return (
    <>
      <ProtectedRoute path="/insurance/3rd-party" component={ThirdParty} />
      <ProtectedRoute
        path="/dashboard/:slug/:path?/:variable?"
        component={Dashboard}
      />

      <ProtectedRoute
        path="/admin/:path?/:variable?"
        component={AdminDashboard}
      />
    </>
  );
};
