//Providers
//
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../google/AuthContext";

export default function CompanyRoute({
  component: Component,
  company,
  ...rest
}) {
  const { role } = useAuth();
  const { pathname } = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        return role && role.role_company ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={`/`} />
        );
      }}
    ></Route>
  );
}
