// Application Providers
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./helpers/google/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Layouts
import Home from "./layouts/Home";
import Login from "./layouts/Login";
import Header from "./components/basic/Header";
import Footer from "./components/basic/Footer";
import { ApplicationRoutes } from "./helpers/basic/ApplicationRoutes";
import theme from "./helpers/basic/Chakra";
import Signup from "./layouts/Signup";
import { useEffect, useState } from "react";
import ScrollToTop from "./helpers/basic/ScrollToTop";

function App() {
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000);
  }, []);

  return (
    //Chakra UI
    <div>
      {!spinner && (
        <ChakraProvider resetCSS="true" theme={theme}>
          {/* Auth Provider wrapping all Routes to check if access is given */}
          <AuthProvider>
            <Router>
              <ScrollToTop />
              <Header />
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Route path="/signup" exact component={Signup} />
                <ApplicationRoutes />
                {/* <ProtectedRoute path="/" exact component={Dashboard} />
            <ProtectedRoute path="/categories" exact component={Categories} />
            <ProtectedRoute
              path="/category/:id"
              exact
              component={SubCategories}
            /> */}
              </Switch>
              <Footer />
            </Router>
          </AuthProvider>
        </ChakraProvider>
      )}
      {spinner && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <img src={process.env.PUBLIC_URL + "/logo.png"} alt="" />
          <img
            style={{ width: "50px" }}
            src={process.env.PUBLIC_URL + "/ripple.gif"}
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default App;
