import {
  CloseButton,
  IconButton,
  Drawer,
  DrawerContent,
  Skeleton,
  DrawerOverlay,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import { Image } from "@chakra-ui/image";
import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/icons";
import React from "react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from "react-icons/fi";
import { Link } from "react-router-dom";

function Sidebar({ loading, slug }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const LinkItems = [
    { name: "Dashboard", to: "/dashboard/" + slug },
    {
      name: "Insurers",
      to: "/admin/companies",
    },
    {
      name: "Pricing Models",
      to: "/admin/models",
    },
    { name: "Contact Details", to: "/admin/contact" },
    { name: "Settings", to: "/admin/settings" },
  ];

  return (
    <Box borderRadius={10} overflow={"hidden"}>
      <Skeleton isLoaded={!loading}>
        <Center
          bg={!loading && "primary.2"}
          color="primary.1"
          py={10}
          px={5}
          display={{ base: "none", lg: "block" }}
        >
          <Text>Super Administrator</Text>
        </Center>
      </Skeleton>

      <SidebarContent
        LinkItems={LinkItems}
        onClose={() => onClose}
        loading={loading}
        display={{ base: "none", lg: "block" }}
        bg={!loading && "primary.1"}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        bg="primary.1"
      >
        <DrawerOverlay />
        <DrawerContent bg="primary.1">
          <SidebarContent
            onClose={onClose}
            LinkItems={LinkItems}
            loading={loading}
          />
        </DrawerContent>
      </Drawer>

      <MobileNav display={{ base: "flex", lg: "none" }} onOpen={onOpen} />
    </Box>
  );
}

export default Sidebar;

const SidebarContent = ({ onClose, loading, LinkItems, ...rest }) => {
  return (
    <Box {...rest} py={10}>
      <Flex
        alignItems="center"
        p={{ base: 8, lg: 0 }}
        justifyContent="space-between"
      >
        <CloseButton
          display={{ base: "flex", lg: "none" }}
          color="primary.1"
          bg="primary.2"
          opacity="0.6"
          onClick={onClose}
          _hover={{ opacity: 1 }}
        />
      </Flex>
      {LinkItems.map((link, index) => (
        <Skeleton
          speed={0.3 + index + 1}
          key={link.name}
          mb={1}
          isLoaded={!loading}
        >
          <NavItem link={link}></NavItem>
        </Skeleton>
      ))}
    </Box>
  );
};

const NavItem = ({ link, ...rest }) => {
  return (
    <Link to={link.to} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="white"
        _hover={{
          bg: "primary.2",
          color: "primary.1",
        }}
        {...rest}
      >
        {link.icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "primary.1",
            }}
            as={link.icon}
          />
        )}
        {link.name}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="space-between"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text>Super Administrator</Text>
    </Flex>
  );
};
