import { Heading, Stack, Text } from "@chakra-ui/layout";
import React from "react";

function ModelUses({ model }) {
  console.log(model);
  return (
    <Stack mt={20}>
      <Heading mt={10} size={"md"}>
        <Text>Vehicle Uses</Text>
      </Heading>
    </Stack>
  );
}

export default ModelUses;
