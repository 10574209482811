import React from "react";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
function ThirdPartySteps({ step }) {
  return (
    <Flex align="center" pt={10} mb={10}>
      <HStack mx={3}>
        <Flex
          h={10}
          w={10}
          bg={step > 0 && "accent.1"}
          color={"white"}
          align="center"
          justify="center"
          borderRadius="50%"
        >
          1
        </Flex>
        {step === 1 && (
          <Text style={{ whiteSpace: "nowrap" }}>Vehicle Details</Text>
        )}
      </HStack>
      {step > 1 && (
        <HStack mx={3}>
          <Flex
            h={10}
            w={10}
            bg={step > 0 && "accent.1"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            2
          </Flex>
          {step === 2 && (
            <Text style={{ whiteSpace: "nowrap" }}>Owner Details</Text>
          )}
        </HStack>
      )}
      {step > 2 && (
        <HStack mx={3}>
          <Flex
            h={10}
            w={10}
            bg={step > 0 && "accent.1"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            3
          </Flex>
          {step === 3 && <Text style={{ whiteSpace: "nowrap" }}>Extras</Text>}
        </HStack>
      )}

      {step > 3 && (
        <HStack mx={3}>
          <Flex
            h={10}
            w={10}
            bg={step > 0 && "accent.1"}
            color={"white"}
            align="center"
            justify="center"
            borderRadius="50%"
          >
            4
          </Flex>
          {step === 4 && <Text style={{ whiteSpace: "nowrap" }}>Checkout</Text>}
        </HStack>
      )}

      <Box bg="primary.1" w="100%" p={0.2} color="white"></Box>
      {step < 2 && (
        <>
          <HStack>
            <Flex
              h={10}
              w={10}
              bg={"primary.1"}
              color={"white"}
              align="center"
              justify="center"
              borderRadius="50%"
            >
              2
            </Flex>
          </HStack>
          <Box bg="primary.1" w={1} p={0.2} color="white"></Box>
        </>
      )}

      {step < 3 && (
        <>
          <HStack>
            <Flex
              h={10}
              w={10}
              bg={"primary.1"}
              color={"white"}
              align="center"
              justify="center"
              borderRadius="50%"
            >
              3
            </Flex>
          </HStack>
          <Box bg="primary.1" w={1} p={0.2} color="white"></Box>
        </>
      )}
      {step < 4 && (
        <>
          <HStack>
            <Flex
              h={10}
              w={10}
              bg={"primary.1"}
              color={"white"}
              align="center"
              justify="center"
              borderRadius="50%"
            >
              4
            </Flex>
          </HStack>
          <Box bg="primary.1" w={1} p={0.2} color="white"></Box>
        </>
      )}
    </Flex>
  );
}

export default ThirdPartySteps;
